import React from 'react';
import { Box, Chip, Tooltip, Typography } from '@mui/material';

export default function GetMoodGenreLanguage({ label, items }) {

  // Check if the items are objects and map them to their labels
  const processedItems = Array.isArray(items) && items.length > 0
    ? (typeof items[0] === 'object' && items[0]?.platformName
        ? items.map((item) => item.platformName) 
        : items) 
    : [];

  const displayedItems = processedItems.slice(0, 1);
  const remainingItems = processedItems.slice(1);

  return (
    <Box className='d-flex align-items-baseline gap-2'>
      <Typography variant="body2"><strong>{label}:</strong></Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
        {/* Render displayed items */}
        {displayedItems.map((item, index) => (
          <Chip
            key={index}
            label={item}
            sx={{ backgroundColor: '#E6EEEF' }}
          />
        ))}

        {/* Render remaining items as a tooltip */}
        {remainingItems.length > 0 && (
          <Tooltip
            title={remainingItems.join(', ')}
            placement="top"
            arrow
          >
            <Chip
              label={`+${remainingItems.length}`}
              sx={{ backgroundColor: '#E6EEEF', cursor: 'pointer' }}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
