import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import SnackbarContainer from '../Snackbar';
import DonutStepper from '../../components/reusableComponents/DonuteStepper';
import ConfirmModal from '../../components/reusableComponents/ConfirmModal';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import WarningModal from '../../components/reusableComponents/WarningModal';
import General from './General';
import FinalReviews from './FinalReviews';
import MusicDemo from './MusicDemo';
import { submitDemo } from '../../_services/creater/creatorDemo.service';
import { getSubmitToList } from '../../_services/demo/Submit_To';
import { getDemoGenre } from '../../_services/demo/Demo_Genre';
import { getDemoLanguage } from '../../_services/demo/Demo_Language';
import { getSingleDemoDetails } from '../../_services/demo/GetSingleDemoDetails';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const numbers = {
    1: 1,
    2: 2,
    3: 3,
  };
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {/* <Check/> */}

      {completed ? (
        <Check />
      ) : (
        <div>{numbers[String(props.icon)]}</div>
      )}

    </ColorlibStepIconRoot>

  );
}
const steps = ['General', 'Demo Composition', 'FinalReview'];
const subHedingSteps = ['General', 'Demo Composition', 'FinalReview'];

export default function AddDemos() {
  const userData = useSelector((state) => state.userData.userData);
  const { demoId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const warningRef = useRef();
  const smScreen = useMediaQuery('(max-width:768px)');
  const [nextBtnDisable, setNextBtnDisable] = useState(true);
  const [prevBtnDisable, setPrevBtnDisable] = useState(true);
  const [isExternalLink, setIsExternalLink] = React.useState([false]);
  const [submitToList, setSubmitToList] = useState([]);

  const [data, setData] = useState(
    {
      demo_name: '',
      demo_description: '',
      genre: '',
      language: '',
      demo_type: '',
      video_link: '',
      album: '',
      external_link: '',
      is_video_available: 'True',
      receiver_types: '',
    }
  );
  const [errors, setErrors] = useState({
    demo_name: false,
    demo_name_helperTxt: false,
    sub_genre: false,
    sub_genre_helperTxt: false,
    language: false,
    language_helpertext: false,
    demo_type: false,
    demo_type_helpertext: false,
    is_video_available: false,
    is_video_available_helpertext: false,
    external_link: false,
    external_link_helpertext: false,
    video_link: false,
    video_link_helpertext: false,
    album: false,
    album_helperTxt: false,
    receiver_types: false,
    submitted_to_helpertext: false,
    demo_description: false,
    demo_description_helperTxt: false,
    demo_file: false,
  });
  const [demo_file, setDemoFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [genre, setGenre] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [stepName, setStepName] = useState(activeStep === 0 ? 'General' : '');
  const [demodataBkp, setDemoDataBkp] = useState();
  const fileInputRef = useRef(null);
  const [demoid, setDemoId] = React.useState(demoId ? demoId : null);
  const [demoType, setDemoType] = useState('');
  const [selectedValue, setSelectedValue] = useState('True');
  const [demoDetails, setDemoDetails] = useState([])
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleLanguageChange = (event, newValue) => {
    setSelectedLanguage(newValue);
    const selectedLanguages = newValue.map((item) => item.id);
    const valueToSend = selectedLanguages.length === 1 ? selectedLanguages[0] : selectedLanguages;
    handleInputChange({ target: { name: 'language', value: valueToSend } });
  };

  const handleGenreChange = (event, newValue) => {
    setSelectedGenre(newValue);
    const selectedGenres = newValue.map((item) => item.id);
    const valueToSend = selectedGenres.length === 1 ? selectedGenres[0] : selectedGenres;
    handleInputChange({ target: { name: 'genre', value: valueToSend } });
  };

  useEffect(function () {
    getSubmitToList().then(function (response) {
      dispatch(setLoader(false))
      setSubmitToList(response.data.data)
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })

  }, []);

  useEffect(() => {
    const handleNextButtonState = () => {
      // Check if the selected value is 'True' and if video_link is defined and not empty
      if (selectedValue === 'True' || (data.video_link && data.video_link.trim() !== '')) {
        setNextBtnDisable(false); // Enable the "Next" button
      } else {
        setNextBtnDisable(true); // Disable the "Next" button
      }
    };
    // Check conditions when selectedValue, isExternalLink, or video_link changes
    handleNextButtonState();
  }, [selectedValue, isExternalLink, data.video_link]);

  useEffect(() => {
    // This effect will run whenever selectedValue, isExternalLink, and data.external_link change
    if (selectedValue === 'False' && isExternalLink[0] && data.external_link) {
      setNextBtnDisable(false); // Enable the "Next" button
    } else {
      setNextBtnDisable(true); // Disable the "Next" button
    }
  }, [selectedValue, isExternalLink, data.external_link]);

  //get the dynamic data
  useEffect(function () {
    getDemoGenre().then(function (response) {
      dispatch(setLoader(false))
      setGenre(response.data.data)
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })
    getDemoLanguage().then(function (response) {
      setLanguageList(response.data ? response.data.data : "")
      dispatch(setLoader(false))
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    }).catch((error) => {
        dispatch(setLoader(false))
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(() => dispatch(setSnackBar(false)), 2000)
        return error.response
      })

  }, []);

  useEffect(() => {
    if (demoDetails) {
      if (demoDetails.demo_type !== undefined) {
        let demo_Type = demoDetails.demo_type.toString();
        demo_Type = demo_Type.charAt(0).toUpperCase() + demo_Type.slice(1);
        setDemoType(demo_Type);
      }
      if (demoDetails.is_video_available !== undefined) {
        // Set the initial state of the radio button based on demoDetails.is_video_available
        let video_available = demoDetails.is_video_available.toString();
        video_available = video_available.charAt(0).toUpperCase() + video_available.slice(1);
        setSelectedValue(video_available);
      }
      if (Array.isArray(demoDetails.genre) && demoDetails.genre.length > 0) {
        setSelectedGenre(demoDetails.genre); // Set selectedGenre with the fetched genre data
        setData({
          ...data,
          genre: demoDetails.genre.map(gen => gen.id),
        });
      } else {
        console.log('Genre not available or not an array:', demoDetails.genre);
      }
      if (Array.isArray(demoDetails.language) && demoDetails.language.length > 0) {
        setSelectedLanguage(demoDetails.language); // Set selectedLanguage with the fetched language data
        setData({
          ...data,
          language: demoDetails.language.map(lang => lang.language),
        });
      } else {
        console.log('Languages not available or not an array:', demoDetails.language);
      }
      if (demoDetails.demo_file){
        setDemoFile(process.env.REACT_APP_BASEURL + 'gndemo/media/' + demoDetails.demo_file);
        setData({
          ...data,
          demo_file: demoDetails.demo_file,
        });
      } else if (demoDetails.video_link) {
        setData({
          ...data,
          video_link: demoDetails.video_link,
        });
      } else if (demoDetails.external_link) {
        setIsExternalLink([true]);
        setData({
          ...data,
          external_link: demoDetails.external_link,
        });
      }
      demoDetails.priority = demoDetails.priority === null ? '' : demoDetails.priority
      // demoDetails.demo_status = demoDetails.demo_status === null ? '' : demoDetails.demo_status
      demoDetails.demo_file = demoDetails.demo_file === null ? '' : demoDetails.demo_file
      demoDetails.external_link = demoDetails.external_link === null ? '' : demoDetails.external_link
      demoDetails.video_link = demoDetails.video_link === null ? '' : demoDetails.video_link
      setDemoDataBkp(demoDetails);
      const newData = { ...demoDetails }
      newData.language = newData.language && Array.isArray(newData.language) && newData.language.map(value => value.id)
      newData.genre = newData.genre && Array.isArray(newData.genre) && newData.genre.map(value => value.id)
      newData.receiver_types = newData.receiver_types && Array.isArray(newData.receiver_types) && newData.receiver_types.map(value => value.id)
      setData(newData)

    }
  }, [demoDetails]);

  //To handle the validatio on input fields
  useEffect(() => {
    validationHandler(activeStep);
  }, [data])

  const checkboxChangeHandler = (event) => {
    const checked = event.target.checked;
    setIsExternalLink([checked]);
    if (selectedValue === 'False' && checked && data.external_link) {
      setNextBtnDisable(false);
    } else {
      setNextBtnDisable(true);
    }
  };

  const updateNextButtonState = (externalLinkChecked, isVideoAvailable) => {
    if (externalLinkChecked && isVideoAvailable === 'False' && data.external_link) {
      setNextBtnDisable(false);
    } else {
      setNextBtnDisable(true);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateSongFile(file)
    event.target.value = ''; //to set file null
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    validateSongFile(file)
    event.target.value = ''; //to set file null
  };


  const handleFileInputRemove = () => {
    setDemoFile(null)
    delete data.demo_file;
    setNextBtnDisable(true)
  }

  function validateSongFile(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const fileType = file.type;
      const fileName = file.name;
      const fileSize = file.size;
      const audio = new Audio();

      audio.addEventListener('loadedmetadata', () => {
        const audioDuration = audio.duration;
        const maxDuration = 90; // 1 minute 30 seconds in seconds

        if (audioDuration > maxDuration) {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage('Audio duration should be less than or equal to 1.5 minutes.'));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return false;
        }

        setDemoFile(reader.result);
        setData({
          ...data,
          ['demo_file']: file,
        });

        return true;
      });

      if (file && fileType.startsWith('audio/')) {
        if (!fileName.match(/\.(wav|WAV|mp3|MP3)$/)) {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadTypeDemo));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return false;
        } else {
          if (fileSize > 20000000) {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadSizeDemo));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            return false;
          } else {
            audio.src = reader.result;
          }
        }
      } else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadTypeDemo));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        return false;
      }
    };
  }

  function getDemoDetails(userId, id) {
    dispatch(setLoader(true))
    getSingleDemoDetails({ 'userId': userId, 'id': id }, 'GET_SPECIFIC_DISPLAY').then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setDemoDetails(response.data.data[0])
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })

  }
  React.useEffect(() => {
    if (userData.id) {
      if (demoId) {
        getDemoDetails(userData.id, demoId)
      }
    }
  }, [userData.id, demoId])

  /* To upload the song poster */
  const isFieldsValid = (errObj) => {
    let array = [];
    for (let key in errObj) {
      array.push(errObj[key])
    }
    if (array.every((item) => item === false)) {
      return true;
    }
    else {
      return false;
    }
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      setStepName(steps[prevActiveStep + 1])
      validationHandler(prevActiveStep + 1)
      return (prevActiveStep + 1)
    });

  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      setStepName(steps[prevActiveStep - 1])
      prevActiveStep === 1 && setPrevBtnDisable(true)
      validationHandler(prevActiveStep - 1)
      return (prevActiveStep - 1)
    });
  };

  const handleReset = () => {
    history.push("/submitdemo");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value
    });
    if (event.target.name === 'external_link') {
      // Check if the other required conditions are met and enable the Next button accordingly
      if (selectedValue === 'False' && isExternalLink[0] && event.target.value) {
        setNextBtnDisable(false);
      } else {
        setNextBtnDisable(true);
      }
    }

    switch (event.target.name) {
      case 'demo_name': {
        const isValid = regexValidator(value, 'alphanumericEndSpace')
        if (!isValid)
          setErrors({ ...errors, demo_name: true, demo_name_helperTxt: formSnackbar.errors.validInput });
        else if (value.length > 254)
          setErrors({ ...errors, demo_name: true, demo_name_helperTxt: formSnackbar.errors.characterError });
        else
          setErrors({ ...errors, demo_name: false, demo_name_helperTxt: false });
        break;
      }

      case 'demo_description': {
        const isValid = value.length > 0 ? regexValidator(value, 'descriptionText') : true;
        if (!isValid)
          setErrors({ ...errors, demo_description: true, demo_description_helperTxt: formSnackbar.errors.validInput });
        else if (value.length > 1000)
          setErrors({ ...errors, demo_description: true, demo_description_helperTxt: formSnackbar.errors.characterCountErrorMax });
        else if (value.length < 100)
          setErrors({ ...errors, demo_description: false, demo_description_helperTxt: formSnackbar.errors.characterCountErrorMin });
        else
          setErrors({ ...errors, demo_description: false, demo_description_helperTxt: false });
        break;
      }
      case 'audio_clip': {
        setDemoFile(value)
        break;
      }
      case 'demo_type': {
        setDemoType(value)
        break;
      }

      case 'is_video_available': {
        setSelectedValue(value);
        updateNextButtonState(isExternalLink, value);
        break;
      }

      default:
        {
          break;
        }
    }
  }
  const validationHandler = (step) => {
    if (step === 0) {
      if (data) {
        if (
          (data.demo_name === '' || !data.demo_name || data.demo_name.length < 1) ||
          (data.genre === '' || !data.genre || data.genre.length < 1) ||
          (data.language === '' || data.language === null || !data.language || data.language.length < 1) ||
          (data.demo_description === '' || !data.demo_description || data.demo_description.length < 100) ||
          (demoType === '' || demoType.length === 0)
        ) {
          setNextBtnDisable(true);
        } else {
          setNextBtnDisable(false);
        }
      }
    } else if (step === 1) {
      if (data.is_video_available != undefined) {
        const { is_video_available } = data;
        let is_video_available1 = is_video_available ? 'True' : 'False'
        if (
          (is_video_available1 === '' || !is_video_available1 || is_video_available1.length < 1) ||
          (data.receiver_types === '' || !data.receiver_types || data.receiver_types.length < 1) ||
          (
            (
              !data.external_link &&
              !data.video_link &&
              !data.demo_file
            )
          )
        ) {
          // Disable the button if is_video_available is not selected
          setNextBtnDisable(true);
          setPrevBtnDisable(false);
        } else {
          if (isFieldsValid(errors) === true) {
            setNextBtnDisable(false)
            setPrevBtnDisable(false)
          }
          else {
            setNextBtnDisable(true)
            setPrevBtnDisable(false)
          }
        }
      }
      if ((data.demo_file != undefined || isValidUrl(data.external_link) || (data.video_link != undefined && data.video_link != null)) && data.receiver_types !== undefined) {
        setNextBtnDisable(false)
      } else {
        setNextBtnDisable(true)
      }
      setPrevBtnDisable(false)
    }
  };
  // To validate that external url is url or not
  function isValidUrl(url) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  }

  //To handle the validatio on input fields
  useEffect(() => {
    validationHandler(activeStep);
  }, [data, demoType])

  const warningHandler = () => {
    warningRef.current.handleClose();
    if (nextBtnDisable === false && activeStep === 2) {
      if (demoid === null || demoid === undefined || demoid === '') {
        saveSubmitaSong('POST');
      } else {
        saveSubmitaSong('UPDATE', demoid);
      }
    }
  }

  const handleFinalSave = () => {
    warningRef.current.handleOpen()
    setData({ ...data })
  }

  function saveSubmitaSong(method, id) {
    if (JSON.stringify(data) === JSON.stringify(demodataBkp)) {
      if (activeStep === 2) {
        handleReset()
      }
      return;
    }
    const postData = data;
    postData.demo_name = postData.demo_name.trim();
    let demoType = postData.demo_type.toString();
    demoType = demoType.charAt(0).toUpperCase() + demoType.slice(1);
    postData.demo_type = demoType;
    let videoAvailable = postData.is_video_available != undefined ? postData.is_video_available.toString() : 'True';
    videoAvailable = videoAvailable != null ? videoAvailable.charAt(0).toUpperCase() + videoAvailable.slice(1) : 'True';
    postData.is_video_available = videoAvailable;


    if (method === 'POST') {
      const formData = new FormData();
      for (const key in postData) {
        postData[key] && formData.append(key, postData[key]);
      }
      formData.append('publisher_id', userData.id);
      formData.append('publisher_type', 'creator');
      dispatch(setLoader(true))
      submitDemo(formData, 'POST').then(function (response) {
        if (response.status === 200 || response.status === 201) {
          dispatch(setLoader(false))
          response && Object.values(data).length > 0 && setDemoId(response.data.id)
          setData(response.data)
          setDemoDataBkp(response.data)
          // if (activeStep === 3) {
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoUploadSuccess))
          setTimeout(() => dispatch(setSnackBar(false)), 4000)
          window.sessionStorage.clear()
          // }
          setTimeout(() => handleReset(), 1000)
        }
        else {
          dispatch(setLoader(false))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
          setTimeout(() => dispatch(setSnackBar(false)), 3000)
        }
      })
    }
    else {
      if (method === 'UPDATE') {
        const formData = new FormData();
        postData.demo_id = id;
        for (const key in postData) {
          if (key === 'demo_file') {
            if (postData[key]?.name) {
              // formData.append(key, postData[key]);
            } else {
              continue;
            }
          }
          if (key === 'external_link' || key === 'video_link') {
            if (postData[key] == '') {
              continue;
            }
          }
          if (key === 'receiver') {
            const receiverTypes = [...new Set(postData[key].map(receiverObj => receiverObj.receiver_types))].join(',');
            formData.append(key, receiverTypes);
          }
          else {
            formData.append(key, postData[key]);
          }
        }
        for (const pair of formData.entries()) {
          console.log(pair[0], pair[1]);
        }
        submitDemo(formData, 'UPDATE').then(function (response) {
          if (response.status === 200 || (response.status === 201)) {
            dispatch(setLoader(false))
            response && Object.values(data).length > 0 && setDemoId(response.data.id)
            setData(response.data)
            setDemoDataBkp(response.data)
            // if (activeStep === 3) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoUploadSuccess))
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            window.sessionStorage.clear()
            // }
            setTimeout(() => handleReset(), 1000)
          }
          else {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            if(response.data.video_link){ 
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.videoRequestFailure))
            }else{
              dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            }
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
          }
        })
      }
    }
  }

  const handleNextClick = (step) => {
    step === steps.length - 1 ? handleFinalSave() : handleNext();
  };

  const handleRouteBack = () => {
    if ((data.demo_name != "" && data.demo_name != undefined) || (data.genre != "" && data.genre != undefined) || (data.language != "" && data.language != undefined || (data.demo_type != "" && data.demo_type != undefined))
    ) {
      ref.current.handleOpen()
    }
    else {
      history.push('/submitdemo')
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage = 'Are you sure you want to leave?';
      e.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [data.demo_name != "" || data.genre != "" || data.language != "" || data.demo_type != ""]);

  const confirmHandler = () => {
    history.push('/submitdemo')
  }

  const getSteps = (activeStep) => {
    switch (activeStep) {
      case 0:
        {
          return <>
            <General
              data={data}
              errors={errors}
              handleInputChange={handleInputChange}
              genre={genre}
              language={languageList}
              demoType={demoType}
              handleLanguageChange={handleLanguageChange}
              handleGenreChange={handleGenreChange}
              selectedLanguage={selectedLanguage}
              selectedGenre={selectedGenre}
            />
          </>
        }
      case 1:
        {
          return <>
            <MusicDemo
              data={data}
              errors={errors}
              demoType={demoType}
              handleInputChange={handleInputChange}
              isExternalLink={isExternalLink}
              checkboxChangeHandler={checkboxChangeHandler}
              audio_clip={demo_file}
              handleDrop={handleDrop}
              handleFileInputChange={handleFileInputChange}
              fileInputRef={fileInputRef}
              handleFileInputRemove={handleFileInputRemove}
              selectedValue={selectedValue}
              submitToList={submitToList}
              setData={setData}

            />
          </>
        }
      case 2:
        {
          return <>
            <FinalReviews
              data={data}
              genre={genre}
              audio_clip={demo_file}
              language={languageList}
              selectedGenre={selectedGenre}
              selectedLanguage={selectedLanguage}
            />
          </>
        }
      default: return;
    }
  } // end

  return (
    <div className={`row`} style={{ padding: '1.2rem 1.2rem 3rem 1.2rem' }} id="addsong-stepper-container">
      <div>
        <button className="icononly-btn" onClick={handleRouteBack}><ArrowBackIcon /> Back</button>
      </div>
      <Box className="box">
        {!smScreen ? (
          <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}  {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : (
          <div>
            <DonutStepper strokeWidth={10} percentage={90} activeStep={activeStep}
              steps={steps} strokeColor='#E41116' trackColor="#d6d6d6" textColor="#161513" fontSize="18px" subHedingSteps={subHedingSteps} />
          </div>
        )}
        <div>
          <div className="card account-bg my-4 border rounded" style={{ backgroundColor: '#fff' }}>
            <div className="card-body">
              <div className="content-area">
                {activeStep === steps.length ? (
                  <React.Fragment>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getSteps(activeStep)}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="card-footer" style={{ backgroundColor: '#fff' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <button
                  disabled={prevBtnDisable}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  className={prevBtnDisable ? 'disablebtn' : 'gn-actionbtn outlined'}
                  style={{ minWidth: '100px' }}
                >
                  PREVIOUS
                </button>
                <Box sx={{ flex: '1 1 auto' }} />
                <button
                  disabled={nextBtnDisable}
                  onClick={() => handleNextClick(activeStep)} className='gn-actionbtn '
                  style={{ minWidth: '100px' }}
                >
                  {activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'}
                </button>
              </Box>
            </div>
          </div>
        </div>
      </Box>
      <div>
        <SnackbarContainer />
        <ConfirmModal confirmHandler={confirmHandler} ref={ref} />
        <WarningModal confirmHandler={warningHandler} ref={warningRef} />
      </div>
    </div>
  );
}