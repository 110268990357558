import React, { useEffect, useState, useRef } from 'react';
import {
    Box, Avatar, Typography, Card, CardContent, IconButton, TextField,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { db } from '../../firebase';
import { collection, query, where, getDocs, addDoc, orderBy, limit, startAfter, onSnapshot } from "firebase/firestore";
import { useSelector } from 'react-redux';
import { formatDate, formatTimestamp } from '../../_helpers/reusablefunctions/dateSetter';

const SingleChat = ({ chatData }) => {
    const userData = useSelector((state) => state.userData.userData);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const chatBoxRef = useRef(null);

    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, message) => {
            const date = formatDate(message.createdAt);
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(message);
            return acc;
        }, {});
    };

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const chatRoomQuery = query(
                    collection(db, 'chatRooms'),
                    where('userIds', 'array-contains', userData?.creator_auth)
                );

                const querySnapshot = await getDocs(chatRoomQuery);

                for (const doc of querySnapshot.docs) {
                    const chatRoomData = doc.data();
                    if (chatRoomData?.userIds.includes(chatData?.creator_auth)) {
                        const messagesCollection = collection(doc.ref, 'messages');
                        const messagesQuery = query(messagesCollection, orderBy('createdAt', 'asc'));

                        onSnapshot(messagesQuery, (messagesSnapshot) => {
                            const liveMessages = messagesSnapshot.docs.map((messageDoc) => ({
                                id: messageDoc.id,
                                ...messageDoc.data(),
                            }));
                            setMessages(liveMessages);
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [chatData, userData]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSend = async () => {
        if (message.trim()) {
            try {
                const chatRoomQuery = query(
                    collection(db, 'chatRooms'),
                    where('userIds', 'array-contains', userData?.creator_auth)
                );

                const querySnapshot = await getDocs(chatRoomQuery);
                let chatRoomRef = null;
                querySnapshot.forEach((doc) => {
                    const chatRoomData = doc.data();
                    if (chatRoomData?.userIds.includes(chatData?.creator_auth)) {
                        chatRoomRef = doc.ref;
                    }
                });

                if (chatRoomRef) {
                    const messagesCollection = collection(chatRoomRef, 'messages');
                    await addDoc(messagesCollection, {
                        text: message,
                        senderId: userData?.creator_auth,
                        createdAt: new Date(),
                    });
                    setMessage('');
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    const groupedMessages = groupMessagesByDate(messages);

    return (
        <Box sx={{ position: 'fixed', bottom: '8%', right: '2%', display: 'flex', flexDirection: 'column', width: 300, height: '80%', borderRadius: 2, overflow: 'hidden', backgroundColor: '#ffffff', boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: '1px solid #ddd' }}>
                <Avatar alt={chatData?.name} src={chatData?.avatar} />
                <Typography sx={{ marginLeft: 1, fontWeight: 'bold', textTransform: 'capitalize' }}>{chatData?.name}</Typography>
            </Box>
            <Box ref={chatBoxRef} sx={{ flex: 1, padding: 2, overflowY: 'auto' }}>
                {Object.entries(groupedMessages).map(([date, msgs]) => (
                    <Box key={date}>
                        <Typography sx={{ textAlign: 'center', fontSize: '12px', color: '#555', marginY: 1 }}>{date}</Typography>
                        {msgs.map((msg) => {
                            const isOwnMessage = msg.senderId === userData?.creator_auth;
                            return (
                                <Box key={msg.id} sx={{ display: 'flex', flexDirection: isOwnMessage ? 'row-reverse' : 'row', alignItems: 'center', marginBottom: 2 }}>
                                    <Card sx={{ backgroundColor: isOwnMessage ? '#FDE8E9' : '#F9F9F9', maxWidth: '75%', boxShadow: '0px 2px 1px -1px #4C4E6433', borderRadius: 2 }}>
                                        <CardContent className='py-2 px-3'>
                                            <Typography sx={{ fontSize: '13px' }}>{msg.text}</Typography>
                                            <Typography sx={{ fontSize: 10, textAlign: 'right', marginTop: '5px' }}>{formatTimestamp(msg.createdAt)}</Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            );
                        })}
                    </Box>
                ))}
                <div ref={messagesEndRef} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderTop: '1px solid #ddd' }}>
                <TextField variant="outlined" size="small" fullWidth placeholder="Write a message..." value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }} />
                <IconButton color="primary" onClick={handleSend}><SendIcon /></IconButton>
            </Box>
        </Box>
    );
};

export default SingleChat;
