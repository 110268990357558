import InstagramImg from '../../img/socialHandles/instagram.png'
import YoutubeImg from '../../img/socialHandles/youtube.png'
import FacebookImg from '../../img/socialHandles/facebook2.png'
import LinkedinImg from '../../img/socialHandles/linkedin.png'
import TwitterImg from '../../img/socialHandles/twitter.png'
import SpotifyImg from '../../img/socialHandles/spotify.png'
import website from '../../img/socialHandles/website.png'
import Imbdlogo from '../../img/socialHandles/imbd-logo.png'

export const getSocialIcon = (socialTypeCode) => {
    switch (socialTypeCode) {
        case 'facebook_1': {
            return <img style={{ widht: '30px', height: '30px' }} src={FacebookImg} alt="Not found" />
        }
        case 'instagram_2': {
            return <img style={{ widht: '30px', height: '30px' }} src={InstagramImg} alt="Not found" />
        }
        case 'twitter_3': {
            return <img style={{ widht: '30px', height: '30px' }} src={TwitterImg} alt="Not found" />
        }
        case 'linkedin_4': {
            return <img style={{ widht: '30px', height: '30px' }} src={LinkedinImg} alt="Not found" />
        }
        case 'youtube_5': {
            return <img style={{ widht: '30px', height: '30px' }} src={YoutubeImg} alt="Not found" />
        }
        case 'spotify_6': {
            return <img style={{ widht: '30px', height: '30px' }} src={SpotifyImg} alt="Not found" />
        }
        case 'website': {
            return <img style={{ widht: '30px', height: '30px' }} src={website} alt="Not found" />
        }
        case 'imdb': {
            return <img style={{ widht: '30px', height: '30px' }} src={Imbdlogo} alt="Not found" />
        }
        default:
            return
    }
}