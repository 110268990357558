import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Modal, Skeleton, useMediaQuery } from '@mui/material';
import album from '../../img/Frame 48096170.png';
import { Edit, Public, Share as ShareIcon } from '@mui/icons-material';
import SortSharpIcon from '@mui/icons-material/SortSharp';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import AlbumSongItem from './AlbumSongItem';
import AddSongToAlbum from './AddSongToAlbum';
import DeleteIcon from '@mui/icons-material/Delete';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useHistory, useParams } from 'react-router-dom';
import { getSongListOfUser } from '../../_services/album/getSongListofUser';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import { useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { reorderSongsInPlaylist } from '../../_services/album/reorderSongsInPlaylist';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import AlbumDrawer from './AlbumDrawer';
import { getSingleAlbum } from '../../_services/album/getSingleAlbum';
import { deleteAlbum } from '../../_services/album/deleteAlbum';
import { updateAlbum } from '../../_services/album/updateAlbum';
import { removeSongFromAlbum } from '../../_services/album/removeSongFromAlbum';
import { reorderSongsInAlbum } from '../../_services/album/reorderSongsInAlbum';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';
import { handleArtistData } from '../submitSong/helperComponent/helperComponent';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { artistCode } from '../submitSong/associatedArtist/artistStaticCodes';
import { readyForRelease } from '../../_services/album/readyForRelease';

function PlaylistDetail({ }) {
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch()
  const ref = useRef();
  // Get the userId param from the URL.
  let { albumId } = useParams();
  const history = useHistory()
  const mdScreen = useMediaQuery('(max-width:768px)');
  const smScreen = useMediaQuery('(max-width:600px)');
  const widthValue = !mdScreen ? '40%' : '100%';
  const heightValue = !mdScreen ? 'calc(100vh - 200px)' : 'auto';
  const [open, setOpen] = React.useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [albumData, setAlbumData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [albumSongLibrary, setAlbumSongLibrary] = useState([])
  const [isUpArrow, setIsUpArrow] = useState(false);
  const [artistList, setArtistList] = useState([])
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const [showReadyForRelease, setReadyForRelease] = React.useState(false)
  const albumValidation = albumSongLibrary.length > 0 && albumSongLibrary.every(item => item.song.song_status === 'In Review') && (albumData.song_status !== "In Review") && (showReadyForRelease == false)
  const handleOpen = () => {
    history.push("/addreleasesong/" + albumId)
  }

  const NoSongs = (<div className="d-flex flex-column align-items-center justify-content-center h-50">
    <h4>No songs in this album yet</h4>
    <button
      className="gn-actionbtn m-4"
      onClick={handleOpen}
      disabled={albumData.length == 0 ? true : albumData.song_status != "Released" && albumData.song_status != "In Review" && albumData.song_status != "Rejected"? false : true}
    >
      Add Track
    </button>
  </div>)


  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  // Function to get unmatched items from array1 compared to array2 based on id
  const getAlbumData = async () => {
    try {
      const response = await getSingleAlbum({ 'userId': userData && userData.id }, { "album_id": albumId })
      const albumSongList = await getSongListOfUser({ 'userId': userData && userData.id }, { "album_id": albumId })
      if (albumSongList) {
        setAlbumData(response.data[0]) //get the albumdata
        // sort based on re-ordered value
        let arrayData = albumSongList.data[0].song;
        const data = arrayData.sort(function (a, b) {
          return parseInt(a.order) - parseInt(b.order);
        });
        setAlbumSongLibrary(data)
      }
    }
    catch (error) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    }
  }
  //call the api to get the single album data
  React.useEffect(function () {
    if (userData.id) {
      getAlbumData()
      getAssociatedArtist()
    }
  }, [userData.id, albumId])

  const albumDeleteHandler = () => {
    deleteAlbum(albumId).then(function (response) {
      // if (response.status === 200 || response.status === 204) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('success'))
      dispatch(setSnackBarMessage(formSnackbar.album.albumDelete))
      history.push('/releasealbum')
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
      // }
    })
    .catch(function (error) {

      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    })
  }


  const removeSongFromPlayList = (songId) => {
   
    removeSongFromAlbum(albumId, songId)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          getAlbumData(albumSongLibrary)
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.album.songRemove))
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })
  }

  const editSongFromPlayList = (songId, albumId) => {
    history.push("/addreleasesong/" + songId + "/" + albumId)
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderedCards = Array.from(albumSongLibrary);
    // const reorderedCards = [...albumSongLibrary];
    const [movedCard] = reorderedCards.splice(source.index, 1);
    reorderedCards.splice(destination.index, 0, movedCard);
    setAlbumSongLibrary(reorderedCards)
    const songIds = reorderedCards.map(item => item.song.song_id)
    const dataObj = { song_ids: songIds.join() }
    reorderSongsInAlbum(albumId, dataObj)
      .then(function (response) {
        //do nothing
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })

  }
  const frameWidth = 100; // Set your desired frame width
  const frameHeight = 200; // Set your desired frame height

  const frameStyle = {
    width: `${frameWidth}%`,
    height: `${frameHeight}px`,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden', // Hide any overflowing content
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 8
  };

  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode);
        break;
      case artistCode.producer:
        handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode);
        break;

      default:
        break;
    }
  }



  const getAssociatedArtist = (name, artistData, isNewArtist) => {
    let postData = {}
      postData['userId'] = userData.id
    associatedArtistsRequestHandler(postData, 'GET')
      .then(response => {
        //set the associated artist list
        setArtistList(response.data.results)
        isNewArtist && artistSelectChangeHandler(name, artistData, isNewArtist)  //call the handler when new artist has beed added
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const readyForReleaseAlbum = () => {
    // let for
    dispatch(setLoader(true))
    readyForRelease({ album_id: albumId }).then(function (r) {
      if (r.status == 200) {
        getAlbumData()
        setReadyForRelease(true)
        dispatch(setLoader(false))
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('success'))
        dispatch(setSnackBarMessage(formSnackbar.album.readyForReleaseAlbum))
        dispatch(setLoader(false))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      }
    }).catch(err => {
      console.error("Error occurred:", err);
    })
  }

  return (
    <div className="container-fluid py-4 creator-album-panel" style={{ overflowY: 'hidden' }}>
      <div className="row m-0">
        <div className="col-12">
          <div>
            <h2>Release Album</h2>
          </div>
          <div className="my-4">
            <button
              className="gn-actionbtn"
              onClick={handleOpen}
              disabled={albumData.length == 0 ? true : albumData.song_status != "Released" && albumData.song_status != "In Review" && albumData.song_status != "Rejected" ? false : true}

            >
              Add Track
            </button>
            <button
              disabled={albumValidation ? false : true}
              className={`gn-actionbtn float-end ${!albumValidation ? 'cursor-not-allowed' : 'cursorPointer'}`}
              onClick={readyForReleaseAlbum}
            >
              Ready For Release
            </button>


          </div>
          <div className='d-flex' style={{ flexWrap: mdScreen ? 'wrap' : 'nowrap', borderRadius: 16 }}>
            <Box
              sx={{

                width: widthValue,
                // height: heightValue,
                minWidth: smScreen ? '100%' : '350px',
                maxWidth: smScreen ? '100%' : '500px',
                border: '1px solid #fff',
                borderRadius: '6px',
                background: 'linear-gradient(rgba(75, 68, 83, 1),rgba(132, 94, 194, 0.97),rgba(198, 114, 179, 0.68),rgba(255, 128, 102, 0.61))'
              }}
            >
              <div className='p-2'>
                <div style={frameStyle}>
                  <img
                    src={albumData && albumData.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + albumData.cover_image : album}
                    alt=""
                    style={imageStyle}
                  />
                </div>
                <div className='text-light mt-4 d-flex justify-content-between'>
                  <div className="d-flex align-items-center">
                    <h6 style={{ fontSize: '20px', margin: '0', width: '180px' }}>{albumData?.album_name}</h6>
                  </div>
                  <div className='position-relative'>
                    <div className='cursorPointer d-flex gap-2' style={{ pointerEvents: showReadyForRelease === false && albumData.song_status != "In Review" ? 'auto' : "none", marginLeft: '10px' }}>
                      <Edit onClick={openDrawer} />

                      <DeleteIcon onClick={() => ref.current.handleOpen()} />
                    </div>
                  </div>
                </div>
                <div className='mt-2 d-flex text-light'>
                  <div>
                    <span style={{ fontSize: '16px' }}>{userData && userData.studio_name}</span>
                  </div>

                </div>
              </div>
            </Box>
            <div style={{ paddingLeft: mdScreen ? '' : '2rem', width: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid #fff',
                  borderRadius: '6px',
                  overflowX: 'hidden',
                  maxHeight: 'calc(100vh - 200px)'
                }}
              >
                {albumSongLibrary && Object.values(albumSongLibrary).length > 0 ? <>{albumSongLibrary.length ? <>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="card-list">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {albumSongLibrary && albumSongLibrary.map((card, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided) => (
                                <div>
                                  <AlbumSongItem albumData={albumData} songData={card} showReadyForRelease={showReadyForRelease} editSongFromPlayList={editSongFromPlayList} removeSongFromPlayList={removeSongFromPlayList} albumId={albumId} provided={provided} albumValidation={albumValidation}/>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </> : NoSongs}</>
                  :
                  NoSongs
                }
              </Box>
            </div>
          </div>
        </div>
      </div>
      {isDrawerOpen && <AlbumDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        albumData={albumData}
        artistSelectChangeHandler={artistSelectChangeHandler}
        primaryArtList={primaryArtList}
        onCreateAlbum={getAlbumData}
        artistList={artistList}
        type="edit" />}
      <DeleteModal cardId={albumData && albumData.album_id} deleteHandler={albumDeleteHandler} ref={ref} />
    </div>
  );
}

export default PlaylistDetail;
