import React, { useEffect, useState } from 'react';
import {
    Avatar, Box, Button, Tab, Tabs, Typography, Rating, Divider, styled, Chip, Stack,
} from '@mui/material';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { useSelector } from 'react-redux';
import ApiService from '../../_services/ApiService';
import SwipUserContent from './SwipUserContent';
import { CardSkeletonCollab } from '../../components/reusableComponents/SkeletonCard';
import NoResults from '../../components/reusableComponents/NoResults';

const filterSongs = [
    { status: 'Pending', label: 'getCollabRequest', value: ["Pending"] },
    { status: '', label: 'getCollabAcceptedRequest', value: ["Accepted"] }
]
const numberOfSkeletonCards = 1
const skeletonCards = Array.from({ length: numberOfSkeletonCards }, (_, index) => (
    <CardSkeletonCollab key={index} />
));
const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    flex: 'wrap',
    gap: 2,
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
const CollabrationNotificationList = () => {
    const { errorSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [collabRequest, setCollabRequest] = useState([]);
    const [activeChip, setActiveChip] = useState('0-0');
    const [activeStatus, setActiveStatus] = useState('Pending');
    const [activeLabel, setActiveLabel] = useState('getCollabRequest');
    const [skeletonLoader, setSkeletonLoader] = useState(true);

    // -------------- get recommendation list 

    function collabRequestApicall(endPoint) {
        setSkeletonLoader(true)
        setCollabRequest([])
        ApiService.get(endPoint, { status: activeStatus }, userData?.id + '/').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setCollabRequest(res?.data)
                setSkeletonLoader(false)
            }
        })
            .catch(error => {
                console.log(error)
                errorSnack(error)
                setSkeletonLoader(false)
            })
    }

    useEffect(() => {
        if (userData?.id) {
            collabRequestApicall(activeLabel)
        }
    }, [userData, activeStatus, activeLabel])

    // ------------ chip change 

    const handleChipClick = (i, data) => {
        setActiveChip(i)
        setActiveStatus(data?.status)
        setActiveLabel(data?.label)
    }
    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel position-relative'>
              <h2>Collaboration-Requests</h2>
            <Stack direction="row" spacing={1}>
                {filterSongs.map((d, i) =>
                    d.value.map((v, index) => (
                        <StyledChip
                            key={`${i}-${index}`}
                            label={v}
                            component="a"
                            variant="outlined"
                            clickable
                            onClick={() => handleChipClick(`${i}-${index}`, d, v)}
                            className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                        />
                    ))
                )}
            </Stack>
            {
                skeletonLoader ?
                    skeletonCards
                    : collabRequest?.results?.length > 0 ? <SwipUserContent
                        collabRequest={collabRequest}
                        head={'Collaboration-Requests'}
                        activeStatus={activeStatus}
                        collabRequestApicall={collabRequestApicall}
                    /> :
                        <NoResults />
            }
        </Box >
    );
};

export default CollabrationNotificationList;
