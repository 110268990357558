import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, IconButton, Modal, Skeleton, Tab, useMediaQuery } from '@mui/material';
import playlist from '../../../../img/Frame 48096170.png';
import { Edit, Public, Share as ShareIcon } from '@mui/icons-material';
import PlaylistDrawer from './PlaylistDrawer';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { deletePlaylist } from '../../../../_services/playlist/deletePlaylist';
import { fetchStreamingPlatformList, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../../redux';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import { useHistory, useParams } from 'react-router-dom';
import { getSinglePlaylist } from '../../../../_services/playlist/getSinglePlaylist';
import { getSongListOfUser } from '../../../../_services/playlist/getSongListofUser';
import { updatePlaylist } from '../../../../_services/playlist/updatePlaylist';
import { removeSongFromPlaylist } from '../../../../_services/playlist/removeSongFromPlaylist';
import DeleteModal from '../../../../components/reusableComponents/DeleteModal';
import { useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorderSongsInPlaylist } from '../../../../_services/playlist/reorderSongsInPlaylist';
import LockIcon from '@mui/icons-material/Lock';
import PlaylistPlatforms from './playlistPlatforms';
import SnackbarContainer from '../../../Snackbar';
import { v4 as uuidv4 } from 'uuid';
import PlaylistSongItem from './PlaylistSongItem';
import CloseIcon from '@mui/icons-material/Close';
import AddSongToPlaylist from './AddSongToPlaylist';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ApiService from '../../../../_services/ApiService';
function PlaylistDetail({ }) {
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch()
  const ref = useRef();
  // Get the userId param from the URL.
  let { playlistId } = useParams();
  const history = useHistory()
  const mdScreen = useMediaQuery('(max-width:768px)');
  const smScreen = useMediaQuery('(max-width:600px)');
  const widthValue = !mdScreen ? '40%' : '100%';
  const heightValue = !mdScreen ? 'calc(100vh - 200px)' : 'auto';
  const [open, setOpen] = React.useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [songlistOfUser, setSonglistOfUser] = useState([])
  const [selectedSongIds, setSelectedSongIds] = useState([])
  const [playlistSongLibrary, setPlaylistSongLibrary] = useState([])
  const [playlistPlatformLibrary, setPlaylistPlatformLibrary] = useState([])
  const [allSongListOfUser, setAllSongListOfUser] = useState([]) //list of all songs of user used to filter the already added song
  const [loadingAddSonglist, setLoadingAddSonglist] = useState(false)
  const [loaderSkeleton, setLoaderSkeleton] = useState(true)
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); setCheckedCount(0) };
  const [sortOrder, setSortOrder] = useState('asc'); // Track sorting order
  const [isUpArrow, setIsUpArrow] = useState(false);
  const [allPlatfromList, setAllPlatformList] = useState([])
  const [tabValue, setTabValue] = useState('1')
  const accountTabs = [
    { id: 1, tabActiveId: "1", tabTitle: 'Song', tabType: 'song' },
    { id: 2, tabActiveId: "2", tabTitle: 'Platform', tabType: 'platform' }]
  const toggleArrow = () => {
    setIsUpArrow((prevState) => !prevState);
  };
  function handleTabChange(e, newValue) {
    setTabValue(newValue)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    borderRadius: '4px',
    p: smScreen ? '0.5rem' : 2,
    maxWidth: 600,
  };

  const [breadcrumbs, setBreadCrumbs] = useState([
    { label: 'Song Hub', link: '/songhub' },
    { label: 'Playlist', link: '/playlist' }]);

  const NoSongs = (<div className="d-flex flex-column align-items-center justify-content-center h-50 w-100">
    <h4>No platform in this playlist yet</h4>
    {/* <button className="gn-actionbtn m-4" onClick={addnewplatform}>
      Add Platform
    </button> */}
  </div>)
  const allSongAddedMsg = (<div className="d-flex align-items-center flex-column justify-content-center" style={{ height: '100px' }}>
    <strong>Add More Songs to Your Grooves</strong>
    <p>To enhance your playlist, consider adding more songs.</p>
  </div>)

  const addPlaylist = (newPlaylist) => {
    //setPlaylistList((prevPlaylists) => [...prevPlaylists, newPlaylist]);
    if (allSongListOfUser.length > 0)
      getPlaylistData(allSongListOfUser)
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const resetCheckCount = () => {
    setCheckedCount(0);
  };

  const handleCheckboxChange = (isChecked, selectedSongId) => {
    setCheckedCount((prevCount) => (isChecked ? prevCount + 1 : prevCount - 1));
    setSelectedSongIds((prevSelectedSongIds) => {
      // If checkbox is checked, add the selectedSongId to the array
      if (isChecked) {
        return [...prevSelectedSongIds, selectedSongId];
      } else {
        // If checkbox is unchecked, remove the selectedSongId from the array
        return prevSelectedSongIds.filter((id) => id !== selectedSongId);
      }
    });
  };

  const handleShareClick = () => {
    const playlistUrl = `${process.env.REACT_APP_CREATORURL}/${userData && userData.userHandle}/playlist` // Replace with your actual playlist URL
    const textField = document.createElement('textarea');
    textField.innerText = playlistUrl;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // Function to get unmatched items from array1 compared to array2 based on id
  function getUnmatchedItems(array1, array2) {
    return array1.filter(item1 => !array2.some(item2 => item2.id === item1.id));
  }
  const getPlaylistData = async (listofSongs) => {
    try {
      const response = await getSinglePlaylist({ id: playlistId })
      setPlaylistData(response.data.data[0]) //get the playlistdata
      let arrayData = response.data.data[0].playlist_releated_platform
      const data = arrayData.sort(function (a, b) {
        return parseInt(a.platform_order) - parseInt(b.platform_order);
      });
      setPlaylistSongLibrary(data)
      setPlaylistPlatformLibrary(response.data.data[0].song)
      setLoaderSkeleton(false)
      setBreadCrumbs([
        // { label: 'Song Hub', link: '/songhub' },
        { label: 'Dashboard', link: '/dashboard' },
        { label: 'Playlist', link: '/playlist' },
        { label: response.data.data[0].playlist_name, link: `/playlistdetail/${playlistId}` }
      ])
      if (response.data.data[0].song && response.data.data[0].song.length > 0) {
        const unmatchedItems = getUnmatchedItems(listofSongs, response.data.data[0].song);
        setSonglistOfUser(unmatchedItems)
        setLoadingAddSonglist(false)
      }
      else {
        setSonglistOfUser(listofSongs)
        setLoadingAddSonglist(false)
      }
    }
    catch (error) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    }
  }

  const getSongListData = async () => {
    setLoadingAddSonglist(true)
    try {
      const response = await getSongListOfUser(userData && userData.id)
      setAllSongListOfUser(response.data.results)
      return response.data.results
    }
    catch (error) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    }
  }
  //call the api to get the single playlist data

  function getData() {
    if (allSongListOfUser.length > 0) {
      getPlaylistData(allSongListOfUser)
    }
    else {
      getSongListData()
        .then(response => {
          getPlaylistData(response)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  React.useEffect(function () {
    if (userData.id) {
      getData()
    }
  }, [userData.id, playlistId])

  const playlistDeleteHandler = () => {
    deletePlaylist({ id: playlistId })
      .then(function (response) {
        if (response.status === 200 || response.status === 204) {
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.playlist.playlistDelete))
          history.push('/playlist')
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })
  }

  const addSongToPlaylist = () => {
    const dataObj = { playlist_song: selectedSongIds.join(',') }
    updatePlaylist(playlistId, dataObj)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          setSelectedSongIds('')
          setCheckedCount(0)
          handleClose()
          getPlaylistData(allSongListOfUser)
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.playlist.playlistUpdate))
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })
  }

  const removeSongFromPlayList = (songId) => {
    const dataObj = { song_ids: songId.toString() }
    removeSongFromPlaylist(playlistId, dataObj)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          getPlaylistData(allSongListOfUser)
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.playlist.songRemove))
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })
  }
  // ------------- playlist drag and drop -------------
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderedCards = [...playlistSongLibrary];
    const [movedCard] = reorderedCards.splice(source.index, 1);
    reorderedCards.splice(destination.index, 0, movedCard);
    setPlaylistSongLibrary(reorderedCards)
    const songIds = reorderedCards.map(item => item.id)
    const dataObj = { platform_ids: songIds }
    reorderSongsInPlaylist(userData.id, playlistId, dataObj)
      .then(function (response) {
        //do nothing
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })

  }

  // ------------- Song drag and drop -------------
  const onDragSongEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderedCards = [...playlistPlatformLibrary];
    const [movedCard] = reorderedCards.splice(source.index, 1);
    reorderedCards.splice(destination.index, 0, movedCard);
    setPlaylistPlatformLibrary(reorderedCards)
    const songIds = reorderedCards.map(item => item.id)
    const dataObj = { song_ids: songIds }
    ApiService.put('songDragdrop',dataObj,'',playlistId)
      .then(function (response) {
        //do nothing
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })

  }

  const frameWidth = 100; // Set your desired frame width
  const frameHeight = 200; // Set your desired frame height

  const frameStyle = {
    width: `${frameWidth}%`,
    height: `${frameHeight}px`,
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden', // Hide any overflowing content
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    // width: 'auto',
    // height: 'auto',
  };

  useEffect(() => {
    dispatch(fetchStreamingPlatformList())
  }, [])

  function addnewplatform() {
    // setPlaylistSongLibrary([{}, ...playlistSongLibrary])
    setPlaylistSongLibrary([{ id: uuidv4() }, ...playlistSongLibrary]);
  }

  return (
    <div className="container-fluid py-4 creator-playlist-panel" style={{ overflowY: 'hidden' }}>
      <div className="row m-0">
        <div className="col-12">
          <div>
            <h2>Song Hub</h2>
            <div className='d-flex'>
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={breadcrumb.label}>
                  {index > 0 && (
                    <div style={{ width: '20px', marginLeft: '10px' }} className='text-secondary'>
                      ◆
                    </div>
                  )}
                  <div title={breadcrumb.label}>
                    <Link to={breadcrumb.link} style={{
                      textDecoration: 'none',
                      color: 'gray',
                      fontSize: '0.8rem',
                      maxWidth: '50px', // Set a maximum width
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',

                    }}>
                      {breadcrumb.label.length > 30
                        ? `${breadcrumb.label.substring(0, 30)}...`
                        : breadcrumb.label}
                    </Link>
                  </div>
                </React.Fragment>
              ))}

            </div>
            <div className='pt-4'>
              <a target="_blank" href={`${process.env.REACT_APP_CREATORURL}${userData && userData.userHandle}/playlist`}>{`${process.env.REACT_APP_CREATORURL}${userData && userData.userHandle}/playlist`}</a>
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4} >
              <div className='d-flex pb-3 mt-3' style={{ flexWrap: mdScreen ? 'wrap' : 'nowrap' }}>
                <Box
                  sx={{

                    width: widthValue,
                    height: heightValue,
                    minWidth: '100%',
                    maxWidth: '100%' ,
                    border: '1px solid #fff',
                    borderRadius: '6px',
                    background: 'linear-gradient(rgba(75, 68, 83, 1),rgba(132, 94, 194, 0.97),rgba(198, 114, 179, 0.68),rgba(255, 128, 102, 0.61))'
                    // background: 'linear-gradient(180.31deg, rgb(0 0 0 / 88%) 31.39%, #41464bd6 70.6%)'
                  }}
                >
                  <div className='p-2'>
                    <div style={frameStyle}>
                      <img
                        src={playlistData && playlistData.playlist_cover_image ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + playlistData.playlist_cover_image : playlist}
                        alt=""
                        style={imageStyle}
                      />
                    </div>
                    <div className='text-light mt-4 d-flex justify-content-between'>
                      <div className="d-flex align-items-center">
                        <h6 style={{ fontSize: '20px', margin: '0', width: '180px' }}>{playlistData.playlist_name}</h6>
                      </div>
                      <div className='position-relative d-flex'>
                        <ShareIcon onClick={handleShareClick} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                        {isCopied && (
                          <span className='position-absolute' style={{ color: 'white', fontSize: '10px', top: 25, left: 5 }}>
                            Copied!
                          </span>
                        )}
                        <Edit onClick={openDrawer} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                        <DeleteIcon onClick={() => ref.current.handleOpen()} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                      </div>
                    </div>
                    <div className='d-flex text-light mt-2'>
                      <div>
                        {playlistData.privacy_status ? <Public /> : <LockIcon />}
                      </div>
                      <div style={{ width: '10px' }}></div>
                      <div>
                        <span style={{ fontSize: '16px' }}>{playlistData.privacy_status ? 'Public' : 'Private'}</span>
                      </div>

                    </div>
                    <div className='mt-2 d-flex text-light'>
                      <div>
                        <span style={{ fontSize: '16px' }}>{userData && userData.stage_name}</span>
                      </div>

                    </div>
                    <div className='d-flex ' style={{ flexWrap: 'wrap' }}>
                      {playlistData && playlistData.playlist_genre && playlistData.playlist_genre.map(item => <div className='artistDetailStyle'>{item}</div>)}
                    </div>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={8} lg={8} >
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    scrollButtons="auto"
                    variant="scrollable"
                    orientation="horizontal"
                    scroller='true'
                    sx={{
                      '& .Mui-selected': {
                        color: '#E41116 !important',
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: '#E41116',
                      },
                    }}
                    onChange={handleTabChange}
                    aria-label="lab API tabs example">
                    {accountTabs?.map((item, i) => {
                      return (
                        <Tab sx={{ fontSize: '13px' }} label={item.tabTitle} key={i} value={item.tabActiveId} />)
                    })}
                  </TabList>
                </Box>

                {(() => {
                  switch (tabValue) {
                    case '1':
                      return (<TabPanel className='px-0 pt-2' value='1'>
                        <Box className='w-100 text-end'>
                          <button
                            className="gn-actionbtn"
                            onClick={handleOpen}
                          >
                            Add Songs
                          </button>
                        </Box>
                        <div style={{ paddingLeft: mdScreen ? '' : '1rem', width: '100%' }}>
                          <Box
                            sx={{
                              width: '100%',
                              maxHeight: 'calc(100vh - 200px)'
                            }}
                          >
                            {playlistData && Object.values(playlistData).length > 0 ? <>{playlistData.song.length ? <>
                              <DragDropContext onDragEnd={onDragSongEnd}>
                                <Droppable droppableId="card-list">
                                  {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                      {playlistPlatformLibrary && playlistPlatformLibrary.map((card, index) => (
                                        <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
                                          {(provided) => (
                                            <div>
                                              <PlaylistSongItem songData={card} removeSongFromPlayList={removeSongFromPlayList} provided={provided} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </> : NoSongs}</> : [1, 2, 3].map(value => <Skeleton height={100} />)}
                          </Box>
                        </div>
                      </TabPanel>);
                    case '2':
                      return (<TabPanel className='px-0 pt-2' value='2'>
                        <Box className='w-100 text-end'>
                          <Button className='gn-actionbtn' onClick={addnewplatform} >
                            Add Platform
                          </Button>
                        </Box>
                        {loaderSkeleton ?
                          <div className='w-100 m-3'>{[1, 2, 3].map(value => <Skeleton height={100} />)}</div>
                          : playlistSongLibrary.length == 0 && !loaderSkeleton ?
                            NoSongs :
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId="card-list">
                                {(provided) => (
                                  <div className='w-100' {...provided.droppableProps} ref={provided.innerRef}>
                                    {playlistSongLibrary && playlistSongLibrary.map((card, index) => (
                                      <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
                                        {(provided) => (
                                          <div>
                                            <PlaylistPlatforms provided={provided}
                                              playlistSongLibrary={card} index={index} setPlaylistSongLibrary={setPlaylistSongLibrary}
                                              allplaylistSongLibrary={playlistSongLibrary} getPlaylistData={getPlaylistData} />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                        }

                      </TabPanel>);
                  }
                })()}
              </TabContext>
            </Grid>
          </Grid>
        </div>
      </div >
      {/* Add Playlist Modal */}
      <Modal
        sx={{ right: 10, left: 10 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}  >
          <div className='d-flex justify-content-between'>
            <div>
              <h4> Add song to playlist</h4>
            </div>
            <div>
              <IconButton onClick={handleClose} aria-label="delete">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Box style={{
            maxHeight: '300px',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            border: '2px solid #fff',
            boxShadow: 24,
            borderRadius: '4px',
            padding: smScreen ? '2px' : '2px 10px',
          }}
          >
            {!loadingAddSonglist ? songlistOfUser && songlistOfUser.length > 0 ? songlistOfUser.map(item => {
              return (
                <div>
                  <AddSongToPlaylist onCheckboxChange={handleCheckboxChange} songData={item} />
                </div>
              )
            }) : allSongAddedMsg : <Skeleton height={100} />}
          </Box>

          <div style={{ marginTop: '20px' }}>
            {checkedCount > 0 && (
              <div>
                <IconButton onClick={resetCheckCount} aria-label="delete">
                  <CloseIcon />
                </IconButton> {checkedCount} Selected
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {songlistOfUser && songlistOfUser.length > 0 &&
                <button className='gn-actionbtn' onClick={addSongToPlaylist} disabled={selectedSongIds.length == 0}>
                  Add
                </button>}
            </div>
          </div>
        </Box>
      </Modal>
      <SnackbarContainer />
      {isDrawerOpen && <PlaylistDrawer isOpen={isDrawerOpen} onClose={closeDrawer} onCreatePlaylist={addPlaylist} playlistData={playlistData} type="edit" />}
      <DeleteModal cardId={playlistData && playlistData.playlist_id} deleteHandler={playlistDeleteHandler} ref={ref} />
    </div >
  );
}

export default PlaylistDetail;
