import React, { useEffect, useState } from 'react';
import {
    Avatar, Box, Button, Tab, Tabs, Typography, Rating, Divider, styled, Chip, Stack,
} from '@mui/material';
import profileImge from '../../img/profileImage.png'
import ApiService from '../../_services/ApiService';
import { useSelector } from 'react-redux';
import { CardSkeleton } from '../../components/reusableComponents/SkeletonCard';
import NoResults from '../../components/reusableComponents/NoResults';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { useHistory } from 'react-router-dom';
const filterSongs = [
    { status: 'Pending', label: 'received', value: ["Pending"] },
    { status: '', label: 'given', value: ["Given"] },
    { status: 'Accepted', label: 'received', value: ["Received"] },
]
const numberOfSkeletonCards = 12
const skeletonCards = Array.from({ length: numberOfSkeletonCards }, (_, index) => (
    <CardSkeleton key={index} />
));
const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    flex: 'wrap',
    gap: 2,
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
const RecommendationList = () => {
    const { errorSnack } = useSnackDispatcher();
    const history = useHistory();
    const userData = useSelector((state) => state.userData.userData);
    const [recommendList, setRecommendList] = useState([]);
    const [activeChip, setActiveChip] = useState('0-0');
    const [activeType, setActiveType] = useState('received');
    const [activeStatus, setActiveStatus] = useState('Pending');
    const [skeletonLoader, setSkeletonLoader] = useState(true);

    // -------------- get recommendation list 

    function recommendationApicall() {
        setSkeletonLoader(true)
        ApiService.get('recommendations', { status: activeStatus, type: activeType }, userData?.id + '/').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setRecommendList(res?.data)
                setSkeletonLoader(false)
            }
        })
            .catch(error => {
                console.log(error)
                errorSnack(error)
                setSkeletonLoader(false)
            })
    }

    useEffect(() => {
        if (userData?.id) {
            recommendationApicall()
        }
    }, [userData, activeStatus, activeType])

    // ------------ chip change 

    const handleChipClick = (i, data, value) => {
        setActiveChip(i)
        setActiveType(data?.label)
        setActiveStatus(data?.status)
    }

    // ---------------- Status change --------

    const statusChangeHandler = (data, status) => {
        ApiService.put('recommendations', { status: status }, {}, `update/${data?.id}`).then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                recommendationApicall()
            }
        })
            .catch(error => {
                errorSnack(error)
                console.log(error)
            })
    }

    const userProfileOpen = (data) => {
        history.push('/artist-profile', { artistData: data?.user_info })
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
            <Box>
                <h2>Recommendation</h2>
            </Box>
            <Box mt={3} mb={5}>
                {/* Chips */}
                <Stack direction="row" spacing={1}>
                    {filterSongs.map((d, i) =>
                        d.value.map((v, index) => (
                            <StyledChip
                                key={`${i}-${index}`}
                                label={v}
                                component="a"
                                variant="outlined"
                                clickable
                                onClick={() => handleChipClick(`${i}-${index}`, d, v)}
                                className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                            />
                        ))
                    )}
                </Stack>

                {/* Feedback Cards */}
                <Box mt={3} sx={{ padding: 3, backgroundColor: '#fff' }}>
                    {skeletonLoader ?
                        <div className="wrapper-card">
                            {skeletonCards}
                        </div> :
                        recommendList.length > 0 ?
                            <Box>
                                {
                                    recommendList.map((data, index) => (
                                        <Box key={index} mb={3}>
                                            {/* User Info */}
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={() => userProfileOpen(data)} >
                                                    <Avatar
                                                        src={data?.user_info?.avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data?.user_info?.avatar : profileImge}
                                                        alt="Sunil Bhadoriya"
                                                        sx={{ marginRight: 2 }}
                                                    />
                                                    <Box>
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {data?.user_info?.name || '-'}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {data?.user_info?.primary_category || '-'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <Rating value={data?.rating} precision={0.1} readOnly size="small" />
                                                    <Typography variant="body2" color="textSecondary" ml={0.5}>
                                                        {`${data?.rating}/5`}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Feedback Content */}
                                            <Typography variant="body2" color="textSecondary" mt={1}>
                                                {data?.feedback || '-'}
                                            </Typography>

                                            {/* Action Buttons */}
                                            <Box display="flex" justifyContent={'space-between'} alignItems={'baseline'} gap={2} mt={2}>
                                                <Typography variant="body2" color="textSecondary" display="block">
                                                    {data?.created_at && getDateAsPerFormat(data?.created_at, "dd/MM/yyyy")}
                                                </Typography>
                                                {activeStatus == 'Pending' ? <Box>
                                                    <Button variant="outlined" onClick={() => statusChangeHandler(data, 'Rejected')}
                                                        className='gn-actionbtn-outline gn-actionbtn-color px-3'>
                                                        Reject
                                                    </Button>
                                                    <Button variant="contained" onClick={() => statusChangeHandler(data, 'Accepted')}
                                                        className='gn-actionbtn px-3 ms-3'>
                                                        Accept
                                                    </Button>
                                                </Box> : null}
                                            </Box>

                                            <Divider sx={{ marginY: 2 }} />
                                        </Box>
                                    ))
                                }
                            </Box>
                            :
                            <NoResults />
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default RecommendationList;
