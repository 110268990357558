import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import playlist from '../../img/Frame 48096170.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import group from '../../img/Group.png'
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
export default function AlbumCard({ albumInfo }) {
  let history = useHistory();


  function handleClick(id) {
    history.push(`/albumdetail/${albumInfo.album_id}`);
  }
  const limitPlaylistName = (name, limit) => {
    if (name.length > limit) {
      return name.substring(0, limit) + '...';
    }
    return name;
  };
  return (
    
    <div className="box-card" onClick={handleClick} role="button" tabIndex="0">
      <div style={{ position: 'relative' }}>
        <div>
        <div style={{ position: 'absolute', bottom: '0', left: '0', background: 'rgba(0,0,0,0.6)', color: '#fff', padding: '5px', fontSize: '12px' }}>
          {albumInfo.album_song_count != 0 ? `${albumInfo.album_song_count} Songs` : 'No Songs'}
        </div>
           <div className='leftStatusCard' style={{ position: 'absolute', textAlign:"center", width:'75px', bottom: '0',background:getSongStatusBgColor(albumInfo.song_status), borderBottomRightRadius:"6px",  color: '#fff', padding: '5px', fontSize: '12px' }}>
          {albumInfo.song_status =="draft" ? "Draft":albumInfo.song_status}
        </div>
        </div>
        <CardMedia
          component="img"
          style={{
            height: '208px',
            objectFit: 'contain',
            background:'none'
          }}
          className="card-media"
          image={albumInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + albumInfo.cover_image : playlist}
        />

      </div>
      <CardContent style={{ padding: '10px 0px' }}>
        <h6 title={albumInfo.album_name}>{limitPlaylistName(albumInfo.album_name, 26)}</h6>
      </CardContent >
    </div >
  );
}