import { useRef, useState } from 'react';
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from '../../firebase';
import backgroundImage from '../../img/bg_image_texture3.png'
import LogoApp from "../../img/logoGntunes.svg";
import Footer from "../footer";
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { login } from '../../_services/auth/auth.service';
import { isLogin, setSelfToken } from '../../_helpers/auth/secureToken';
import { useDispatch } from "react-redux";
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { setLoader } from '../../redux';
import { RegisterUser } from '../../_services/register';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useFirebaseAuthentication from '../../_helpers/reusablefunctions/firebaseLoginFunctions';

const textInfo = {
  fontSize: '12px',
  marginTop: '10px',
}

function PhoneAuth(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const inputRefs = useRef([]);
  const otpLength = 6;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpCode, setOtpCode] = useState(Array(otpLength).fill(""));
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [reCAPTCHAToken, setReCAPTCHAToken] = useState(null);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [resendTimer, setResendTimer] = useState(30); // Timer for resend OTP
  const [canResend, setCanResend] = useState(false);
  const [openEmailPhone, setOpenEmailPhone] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorPass, setErrorPass] = useState(false);
  const [showPassword, setShowConfirmPass] = useState(false);
  const [showVerificationMesage, setShowVerificationMesage] = useState('');
  const { sendOTP, verifyOTP, loginClickHandler } = useFirebaseAuthentication({
    setConfirmationResult,
    confirmationResult,
    setReCAPTCHAToken,
    setErrorMsg,
    from: { pathname: "/" },
    phoneNumber,
    otpCode,
  });
  // ----------------phone and otp change handler ----------
  const phoneChangeHandler = (phone) => {
    setPhoneNumber(phone)
    phone.length != 12 ? setError(true) : setError(false)
  }
  const handleChange = (index, value) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otpCode];
      newOtp[index] = value;
      setOtpCode(newOtp);
      // Move to the next input field
      if (value && index < otpLength - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  // Start a countdown timer
  const startResendTimer = () => {
    setCanResend(false);
    let time = 30; // 30 seconds cooldown
    setResendTimer(time);

    const interval = setInterval(() => {
      time -= 1;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(interval);
        setCanResend(true);
      }
    }, 1000);
  };

  // Resend OTP function
  const resendOTP = async () => {
    if (!canResend) return; // Prevent spamming
    await sendOTP(); // Resend OTP
    startResendTimer(); // Restart timer
  };

  // ------------------- Email and Password login

  const handleEmailLogin = async () => {
    setErrorPass(false);
    dispatch(setLoader(true));

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);

      dispatch(setLoader(false));
      if (!result.user.emailVerified) {
        setErrorPass(true)
        setShowVerificationMesage("Please verify your email before logging in.");
        return;
      }
      setErrorPass(false)
      setShowVerificationMesage('')
      const uid = result.user.uid;

      await loginClickHandler(uid)
      console.log("Email login successful!", result);
    } catch (error) {
      setErrorPass(true);
      dispatch(setLoader(false));
      setShowVerificationMesage('Email not found. Please sign up to create an account.')
      console.error("Error logging in with email:", error.message);
    }
  };

  if (isLogin()) {

    return <Redirect to={from.pathname} />;
  } else {
    return (
      <Box className="container-fluid  " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <Box className="row">
          <nav className="navbar navbar-light  border pb-0">
            <Box className="container-fluid">
              <a className="navbar-brand p-3" href="https://gntunes.com/">
                <img src={LogoApp} alt="logo" />
              </a>
            </Box>
          </nav>
          <Box style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
            <Box className="mt-4 mb-4 p-4 border rounded shadow bg-white">
              {!openEmailPhone ? <form action="" method="" className="row g-3">
                <Box id="recaptcha-container"></Box>
                {!confirmationResult ? (
                  <>
                    <h4 className="text-center mt-0">Enter Phone Number </h4>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      name="phone_number"
                      onChange={phoneChangeHandler}
                      specialLabel="Enter phone number"
                    />
                    {(error || errorMsg) && (
                      <Typography color="error" variant="caption" display="block" gutterBottom>{errorMsg || 'Please enter valid number' }</Typography>
                    )}
                    <Typography variant='body2' sx={textInfo}>You will receive an SMS verification that may apply message and data rates.</Typography>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                      <Button variant='text' onClick={() => setOpenEmailPhone(true)}
                        className='gn-actionbtn-color justify-content-start text-capitalize'>Sign In with Email</Button>
                      <Button variant="contained"
                        className='gn-actionbtn px-3 ms-3'
                        // onClick={verifyOTP}
                        onClick={sendOTP}
                        disabled={error}>Send OTP</Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <h4 className="text-center mt-0">Enter authentication code </h4>
                    <Typography variant='body2' className='text-center'>Enter the 6-digit that we have sent via the phone number +91 {phoneNumber}</Typography>
                    <Box display="flex" justifyContent="center" gap={2} >
                      {otpCode.map((digit, index) => (
                        <TextField
                          key={index}
                          value={digit}
                          onChange={(e) => handleChange(index, e.target.value)}
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                          variant="outlined"
                          size="small"
                          inputProps={{
                            maxLength: 1,
                            style: {
                              textAlign: "center",
                              fontSize: "14px",
                              width: "20px",
                              height: "30px",
                            },
                          }}
                        />
                      ))}
                      {errorMsg}
                    </Box>
                    <Box display={'flex'} textAlign={'center'} justifyContent={'space-between'} >
                      <Button
                        onClick={resendOTP}
                        disabled={!canResend} // Disable button while timer is running
                      >
                        {canResend ? "Resend OTP" : `Resend in ${resendTimer}s`}
                      </Button>
                      <Button variant="contained"
                        className='gn-actionbtn px-3 ms-3'
                        onClick={verifyOTP}
                        disabled={error}>Verify OTP</Button>
                    </Box>
                    <Typography variant='body2' sx={{ ...textInfo, textAlign: 'center' }}>By continuing, you agree to our
                      <a href='https://gntunes.com/terms-condition' className='gn-actionbtn-color fw-bold text-decoration-none' target='_blank'> Terms of Service</a> and
                      <a href='https://gntunes.com/privacy' className='gn-actionbtn-color fw-bold text-decoration-none' target='_blank'> Privacy Policy</a>.</Typography>
                  </>
                )}
              </form>
                :
                <Box >
                  <form action="" method="" className="row g-3">
                    <h4 className="text-center "> Email Sign In </h4>

                    <TextField
                      label="Email"
                      type="email"
                      value={email}
                      error={errorPass}
                      helperText={errorPass ? showVerificationMesage : ''}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"

                    />

                    <TextField
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      type={showPassword ? 'text' : 'password'}
                      error={errorPass}
                      helperText={"Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character."}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => setShowConfirmPass(!showPassword)} >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    />
                    < Box className="" >
                      <a href="/forgot-password" className="gn-actionbtn-color fw-bold text-decoration-none fs-09">
                        Forgot Password?
                      </a>
                    </Box >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                      <Typography variant="body2">
                        <span > Create account? </span>
                        <a href="/register" className="gn-actionbtn-color fw-bold text-decoration-none">
                          Sign Up
                        </a>
                        <span> or </span>
                        <span onClick={() => setOpenEmailPhone(false)} className="cursorPointer gn-actionbtn-color fw-bold text-decoration-none">
                          Login with phone
                        </span>
                      </Typography>
                      <Button variant="contained"
                        className='gn-actionbtn px-3 ms-3'
                        onClick={handleEmailLogin} sx={{ mt: 1 }}>
                        Sign In
                      </Button>
                    </Box>
                    <Typography variant='body2' sx={{ marginTop: '20px', textAlign: 'center' }}>By continuing, you agree to our
                      <a href='https://gntunes.com/terms-condition' className='gn-actionbtn-color fw-bold text-decoration-none' target='_blank'> Terms of Service</a> and
                      <a href='https://gntunes.com/privacy' className='gn-actionbtn-color fw-bold text-decoration-none' target='_blank'> Privacy Policy</a>.</Typography>
                  </form>
                  {/* // <EmailPasswordFirebaseLogin setOpenEmailPhone={setOpenEmailPhone} />} */}
                </Box >
              }
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    );
  }
}
export default PhoneAuth;

