import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../../redux"; // Adjust import based on your project

const useHandleRazorPayment = (paramsData, totalPayment) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Dynamically load Razorpay script
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        script.onload = () => console.log("Razorpay SDK loaded.");
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePayment = async () => {
        try {
            dispatch(setLoader(true)); // Show loader

            // Ensure Razorpay is available
            if (!window.Razorpay) {
                alert("Razorpay SDK not loaded. Please try again later.");
                dispatch(setLoader(false)); // Hide loader
                return;
            }

            // Configure Razorpay options
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_KEY, // Razorpay Key ID
                amount: totalPayment * 100, // Convert to paise (smallest currency unit)
                currency: "INR",
                name: "Your Company Name",
                description: "Payment for Subscription",
                handler: (response) => {
                    console.log("Payment successful:", response);
                    alert(`Payment Successful! Payment ID: ${response.razorpay_payment_id}`);
                    // Further actions (e.g., update payment status)
                },
                prefill: {
                    name: paramsData.name || "John Doe",
                    email: paramsData.email || "john.doe@example.com",
                    contact: paramsData.contact || "9999999999",
                },
                theme: {
                    color: "#F37254",
                },
                modal: {
                    ondismiss: () => {
                        console.log("Payment popup closed by user.");
                    },
                },
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error("Payment failed:", error);
            alert("An error occurred during payment. Please try again.");
        } finally {
            dispatch(setLoader(false)); // Hide loader
        }
    };

    return handlePayment;
};

export default useHandleRazorPayment;
