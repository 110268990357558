import React,{useState,useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import Songtable from './SongListTable';
import SubmitSongCard from './SongListCard';
import AddIcon from '@mui/icons-material/Add';
import SearchBox from '../../components/reusableComponents/SearchBox';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, setLoader,setSnackBar,setSnackBarMessage, setSnackBarVariant,fetchSongReleaseList } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import {  Link, useMediaQuery } from '@mui/material';
import SongFilters from './SongFilters';
import Chip from '@mui/material/Chip';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';


function SongList(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const smScreen = useMediaQuery('(max-width:768px)')
    //to get the search input value
    const[searchInput, setSearchInput] = useState(null)
    const [layoutComponent,setLayoutComponent] = useState(true)
    const [songList, setSongList] = useState([])
    const [renderComponent, setRenderComponent] = useState()
    /* const [open, setOpen] = useState(false) */
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [isFilterIniated, setIsFilterInitiated] = useState(false)
    const [filterPayload, setFilterPayload] = useState(null)

    useEffect(function () 
    {
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
            if(searchInput !=null && searchInput.length>0)
              getSearchSongData(searchInput,userData.id)
            else if (isFilterIniated)
            {
              applyAdvanceFilters(filterPayload)
            }  
            else
              getData(userData.id,page,rowsPerPage);
            dispatch(fetchSongReleaseList())
        }
    }, [userData.id,rowsPerPage, page]);

    const handleChangeRowsPerPage = (event) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
      setRowsPerPage(newRowsPerPage);
      setPage(newPage);
      //getData(userData.id, newPage, newRowsPerPage);

    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);

    };

    const breadcrumbs = [
      { label: 'Release Song', link: '/releases' },
      { label: 'Album', link: '/releasealbum' },
    ];

    function getData(userId,pageOffset,recordLimit) 
    {   
        const offset = pageOffset ;
        const limit = recordLimit;
        dispatch(setLoader(true))
        submitSong({'userId':userId},'GETFORSEPECICARTIST',limit,offset).then(function (response) {
        dispatch(setLoader(false))  
        if(response.status===200) {
            setSongList(response.data)
            layoutComponent?setRenderComponent(<SubmitSongCard songListData = {response.data}
              handleChangePage={handleChangePage} 
              handleChangeRowsPerPage={handleChangeRowsPerPage} 
              page={page} 
              rowsPerPage={rowsPerPage}/>):setRenderComponent(<Songtable  songListData = {response.data} 
                handleChangePage={handleChangePage} 
                handleChangeRowsPerPage={handleChangeRowsPerPage} 
                page={page} 
                rowsPerPage={rowsPerPage}/> )
        }
        else{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
        }
        }).catch(function (error) {
            dispatch(setLoader(false))
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
            return error.response;
        })
    }

    useEffect(()=>{
      if(!layoutComponent)
      { 
        setRenderComponent(<SubmitSongCard songListData = {songList} 
          handleChangePage={handleChangePage} 
          handleChangeRowsPerPage={handleChangeRowsPerPage} 
          page={page} 
          rowsPerPage={rowsPerPage}/>)
        setLayoutComponent(true)
      }
    },[smScreen])

    function handleClick() {
      history.push("/addreleasesong");

    }
    //to get the searchbox input and filter the data
    //to hit the search and display the result
    const getSearchSongData = (value,userId)=>{
      const offset = page ;
      const limit = rowsPerPage;
      dispatch(setLoader(true))
      submitSong({'searchValue':value,publisherType:'creator',publisherId:userId},'SEARCH',limit,offset).then(function (response) {
        dispatch(setLoader(false))
        if(response.status===200) {
            setSongList(response.data)
            setRenderComponent(null)
            copyHandleLayoutRerender(layoutComponent,response.data)
        }
        else{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
        }
        }).catch(function (error) {
            dispatch(setLoader(false))
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
            return error.response;
        })
    }
    const handleSearchInput = (value)=>{
      setSearchInput(value)
      //getSearchSongData(value) //call the search to filter the data
    }
    useEffect(() => {
      const getData = setTimeout(() => {
        //searchInput!=null && getSearchSongData(searchInput,userData.id)  //commented the code to avoid multiple calls of same 
        if(page>0)
          page>0&&setPage(0)//to set offeset to 0 on every fresh search
        else
          searchInput!=null && getSearchSongData(searchInput,userData.id)
      }, 400)
  
      return () => clearTimeout(getData)
    }, [searchInput])
    //get sets the list data

    //advance search function
    const handleAdvanceFilter = (payload) =>{
      if(page>0)
      { 
        setIsFilterInitiated(true)
        setPage(0)
        setFilterPayload(payload)
      }
      else
      {
        applyAdvanceFilters(payload)
      }
    }

    const applyAdvanceFilters = (payload) => {
      const offset = page ;
      const limit = rowsPerPage;
      dispatch(setLoader(true))
      let dataObj = {publisher_id:userData.id, publisher_type:'creator',...payload}
      submitSong(dataObj,'ADVANCESEARCH',limit,offset).then(function (response) {
        dispatch(setLoader(false))
        if(response.status===200) {
            setIsFilterApplied(true)
            setSongList(response.data)
            setRenderComponent(null)
            copyHandleLayoutRerender(layoutComponent,response.data)
        }
        else{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
        }
        }).catch(function (error) {
            dispatch(setLoader(false))
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
            return error.response;
        })
    }
    const removeFilters = () =>{
      setIsFilterApplied(false)
      getSearchSongData(undefined,userData.id)
    }

    

    //to set the current display component card or table view
    const handleLayoutComponent = (currentComp)=>{
     setLayoutComponent(currentComp) 
     currentComp?setRenderComponent(<SubmitSongCard songListData = {songList}  
      handleChangePage={handleChangePage} 
      handleChangeRowsPerPage={handleChangeRowsPerPage} 
      page={page} 
      rowsPerPage={rowsPerPage}/>):setRenderComponent(<Songtable  songListData = {songList} 
        handleChangePage={handleChangePage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        page={page} 
        rowsPerPage={rowsPerPage}/> )
    }
    //to render the component on search
    const copyHandleLayoutRerender = (currentComp, songData) =>{
      setLayoutComponent(currentComp) 
      currentComp?setRenderComponent(<SubmitSongCard songListData = {songData} 
        handleChangePage={handleChangePage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        page={page} 
        rowsPerPage={rowsPerPage}/>):setRenderComponent(<Songtable  songListData = {songData} 
          handleChangePage={handleChangePage} 
          handleChangeRowsPerPage={handleChangeRowsPerPage} 
          page={page} 
          rowsPerPage={rowsPerPage}/> )
     
    }

  

  return (
    <div className={` ${smScreen?'p-2':''}`} style={{padding:'1rem 1rem 3rem 1rem'}}>
        {/* <h2>Submit your song</h2> */}
        <h2>Song List</h2>
        {/* row 2 */}
        {/* <div className="d-flex">
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={breadcrumb.label}>
                  {index > 0 && <div style={{ width: '20px', marginLeft: '10px' }} className='text-secondary'>◆</div>}
                  <div>
                    <Link to={breadcrumb.link} style={{ textDecoration: 'none', color: 'gray', fontSize: '0.8rem' }}>
                      {breadcrumb.label}
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div> */}
        <div className={`d-flex justify-content-between`}>
          <div className='flex-grow-1' style={{maxWidth:'18rem'}}><SearchBox  handleSearchInput={handleSearchInput}/></div>
          <div className='d-flex justify-content-end'>
            <div className=''><SongFilters isFilterApplied={isFilterApplied} applyAdvanceFilters={handleAdvanceFilter}/></div>
            <div>
              {!smScreen?
              <SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent}/>:''
}
              </div>
          </div>
         </div>
        <div className='mt-4 d-flex justify-content-between'>
            <button className='gn-actionbtn' onClick={handleClick} ><AddIcon />New Release</button>
            <div>
              {isFilterApplied?<Chip label="Clear all filters" onDelete={removeFilters} />:<p>Showing all data</p>}
            </div>
        </div>
        <div>
          {renderComponent}
        </div>  
    </div>
  )
}

export default SongList