import React, { useState } from "react";
import { setLoader } from "../../redux";
import { linkWithCredential, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { login } from "../../_services/auth/auth.service";
import { setSelfToken } from "../auth/secureToken";
import { useHistory } from 'react-router-dom';
import { RegisterUser } from "../../_services/register";

const useFirebaseAuthentication = ({ setConfirmationResult, confirmationResult, setReCAPTCHAToken, setErrorMsg, from, phoneNumber, otpCode }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    async function registerClickHandler(uid) {
        dispatch(setLoader(true));
        try {
            const response = await RegisterUser({ creator_auth: uid })
            if (response.status === 200) {
                dispatch(setLoader(false));
            }
            else {
                dispatch(setLoader(false));
            }
        }
        catch (error) {
            console.error("Error during login:", error);
        } finally {
            dispatch(setLoader(false));
        }
    }

    async function loginClickHandler(uid) {
        dispatch(setLoader(true));
        try {
            const response = await login({ username: uid });
            if (response.status === 200) {
                dispatch(setLoader(false));
                const output = response.data;
                if (output) {
                    setSelfToken(output.token);
                    history.replace(from.pathname);
                } else {
                    console.log(response);
                }
            }
            else {
                console.log(response.data.error);
                return response.data.error
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            dispatch(setLoader(false));
        }
    }


    const sendOTP = async () => {
        if (!phoneNumber || phoneNumber.length < 10) {
            setErrorMsg("Invalid phone number");
            return;
        }

        dispatch(setLoader(true));
        try {
            const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',
                callback: (response) => {
                    console.log('reCAPTCHA solved:', response);
                    setReCAPTCHAToken(response)
                },
            });
            const result = await signInWithPhoneNumber(auth, '+' + phoneNumber, appVerifier);
            dispatch(setLoader(false));
            setConfirmationResult(result);
            console.log('OTP sent!');
        } catch (error) {
            dispatch(setLoader(false));
            setErrorMsg(error.message)
            console.error('Error sending OTP:', error.message);
        }
    };


    // ---------------- Verify OTP and Link Phone Number
    const verifyAndLinkPhone = async () => {
        try {
            if (!confirmationResult) {
                console.error("No confirmation result found.");
                setErrorMsg("No confirmation result found.");
                return;
            }

            const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otpCode.join(""));
            const user = auth.currentUser; // Ensure user is logged in with email first

            if (user) {
                await linkWithCredential(user, credential);
                console.log("Phone number linked successfully! UID:", user.uid);
            } else {
                setErrorMsg("User must be logged in before verifying phone.");
                console.error("No authenticated user found!");
            }
        } catch (error) {
            console.error("Error verifying OTP:", error.message);
            setErrorMsg(error.message);
        }
    };


    // ----------- verification otp --------------
    const verifyOTP = async () => {
        try {
            if (!confirmationResult) {
                console.error('No confirmation result found.');
                return;
            }

            const result = await confirmationResult.confirm(otpCode.join(""));
            // console.log('OTP verified successfully!', result.user);
            // const uid = ""lpPdTPdBrFcAiUjw3Odil2taIHd2"";
            const uid = result.user.uid;
            const user = auth.currentUser;

            if (user && user.uid === uid) {
                console.log("User already logged in. Linking phone...");
                await verifyAndLinkPhone();
            } else {
                console.log("Logging in the user...");
                // Attempt to login
                const loginResponse = await loginClickHandler(uid);
                console.log('OTP verified successfully!', loginResponse);

                if (loginResponse[0]) {
                    console.warn("User not found. Attempting to register...");
                    try {
                        await registerClickHandler(uid);
                        console.log("Registration successful. Logging in...");
                        await loginClickHandler(uid);
                    } catch (registerError) {
                        console.error("Registration failed:", registerError);
                        setErrorMsg(registerError)
                    }
                }
            }
        } catch (otpError) {
            console.error('Error verifying OTP:', otpError.message);
            setErrorMsg(otpError.message)
        }
    };

    return { sendOTP, verifyOTP, loginClickHandler, registerClickHandler };

}
export default useFirebaseAuthentication;