import React from 'react';
import { Box, Typography, Grid, Chip, Avatar, Button, IconButton, Rating } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import profileImge from '../../img/profileImage.png'
import SwipImageCarousel from '../../module/Swip/SwipImageCarousel';
import { getSocialIcon } from './socialIcons';

export default function ArtistProfile() {
    const location = useLocation()?.state
    let history = useHistory();
    const getUserDetail = location?.artistData

    // --------------- commoncategorymoodgenre -----------

    const GenreMoodCategory = ({ title, data, labelKey }) => {
        return (
            <Box>
                <Typography variant="h6" mt={2} gutterBottom>
                    {title}
                </Typography>
                <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        {data?.map((item) => (
                            <Chip key={item.id} label={item[labelKey]} sx={{ backgroundColor: '#E6EEEF' }} />
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    };

    const SocialLinks = ({ data }) => {
        return (
            <>
                <Typography variant="h6" mt={2} gutterBottom>
                    Socials
                </Typography>
                <Box>
                    <Box className='flex-wrap' sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
                        {data?.map((d, i) => (
                            <IconButton key={i} sx={{ backgroundColor: '#FFF1F1', fontSize: '12px', width: d?.url?.length > 50 ? '75%' : 'auto' }}
                                className='rounded-2 gap-2 wordBreak justify-content-start' href={d.url} target="_blank">
                                {getSocialIcon(d?.socialType?.socialTypeCode)}
                                {d?.url}
                            </IconButton>
                        ))}

                    </Box>
                </Box>
            </>
        )
    }

    // ------------------experience comman const for sender and collab

    const ExperienceLinks = ({ data }) => {
        return (
            <>
                <Typography variant="h6" mt={2} gutterBottom>
                    Experience
                </Typography>
                <Box>
                    {/* <Typography variant="body2"><strong>Artist Experience:</strong></Typography> */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        {data?.map((experience, index) => (
                            <Box key={index}>
                                <Typography variant='body2' component={'div'} className='fw-bolder d-flex align-items-baseline gap-2'>
                                    Company Name:
                                    <Typography variant="subtitle1" color="textSecondary" >{experience?.companyName}</Typography>
                                </Typography>
                                <Typography variant='body2' component={'div'} className='fw-bolder d-flex align-items-baseline gap-2'>
                                    Title:
                                    <Typography variant="subtitle1" color="textSecondary" >{experience?.title}</Typography>
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </>
        )
    }

    // ------------------recommendation comman const for sender and collab

    const RecommendationLink = ({ data }) => {
        return (
            <>
                <Typography variant="h6" mt={2} gutterBottom>
                    Recommendations
                </Typography>
                <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 1, marginTop: 1, marginRight: 2, marginBottom: 3 }}>
                        {data?.map((data, index) => (
                            <Box key={index}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <Avatar
                                            src={data?.recommended_by_avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + getUserDetail?.recommended_by_avatar : profileImge}
                                            alt="Sunil Bhadoriya"
                                            sx={{ marginRight: 2 }}
                                        />
                                        <Box>
                                            <Typography variant="body1" fontWeight="bold">
                                                {data?.recommended_by_name || '-'}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {data?.recommended_by_role || '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Rating value={data?.rating} precision={0.1} readOnly size="small" />
                                        <Typography variant="body2" color="textSecondary" ml={0.5}>
                                            {`${data?.rating}/5`}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* Feedback Content */}
                                <Typography variant="body2" color="textSecondary" mt={1}>
                                    {data?.feedback || '-'}
                                </Typography>
                            </Box>
                        )) || '-'}
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel '>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                <h2>Artist Profile</h2>
            </Box>
            <Box sx={{ padding: '20px', margin: '20px 0px 50px 0px', height: "550px", boxShadow: '0px 2px 16px 1px rgba(76, 78, 100, 0.12)', backgroundColor: '#fff', borderRadius: '10px' }}>
                <Grid container spacing={3} height={'95%'}>
                    <Grid item xs={12} sm={12} md={6} lg={4} height={'100%'} >
                        <SwipImageCarousel />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} height={'100%'}>
                        <Box sx={{ overflowY: 'auto', height: '95%' }}>
                            <Typography variant="h6" gutterBottom>
                                General Information
                            </Typography>

                            <Box className='d-flex gap-3 flex-wrap' >
                                <Typography variant="body2"><strong>Name:</strong> {getUserDetail?.name || '--'}</Typography>
                                <Typography variant="body2"><strong>Email:</strong> {getUserDetail?.email || '--'}</Typography>
                                <Typography variant="body2"><strong>Language:</strong> {getUserDetail?.languages?.map(d => d.language).join(',') || '--'}</Typography>
                                <Typography variant="body2"><strong>Location:</strong> {getUserDetail?.city || '--'}</Typography>
                                <Typography variant="body2" component={'div'} width={'100%'}><strong>About:</strong> {getUserDetail?.about || '--'}</Typography>
                            </Box>
                            {/* ---------------- comman code for category, mood and genre */}
                            <GenreMoodCategory
                                title="Artist Category"
                                data={getUserDetail?.category || '--'}
                                labelKey="category"
                            />
                            <GenreMoodCategory
                                title="Genre"
                                data={getUserDetail?.genres || '--'}
                                labelKey="genre"
                            />
                            <GenreMoodCategory
                                title="Songs"
                                data={getUserDetail?.songs || '--'}
                                labelKey="songTitle"
                            />
                            {/* ----------------------- social --------------- */}
                            <SocialLinks
                                data={getUserDetail?.social || '--'}
                            />
                            {/* -------- experience area */}
                            <ExperienceLinks
                                data={getUserDetail?.experience || '--'}
                            />

                            {/* -------- recommendation area */}

                            {getUserDetail?.received_recommendations.length ? <RecommendationLink
                                data={getUserDetail?.received_recommendations || '--'}
                            /> : null}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )

}