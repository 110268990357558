import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import ApiService from '../_services/ApiService';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SongAds() {
    const [open, setOpen] = useState(false);
    const [adsList, setAdsList] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        ApiService.get('adsPromotion').then((res) => {
            if (res.status === 200 || res.status == 201) {
                setAdsList(res?.data?.data[0])
            }

        }).catch(error => {
            console.log('error', error)
        })
        const intervalId = setInterval(() => {
            setOpen(true);
        }, 10 * 60 * 1000); // Open dialog every 10 minutes (10 * 60 * 1000 ms)

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'sm'}
        >

            <DialogContent>
                <Box className='pb-3 text-end' onClick={() => setOpen(false)} >
                    <CloseIcon />
                </Box>
                <Card>

                    <CardContent className='d-flex gap-2 align-items-center'>
                        <Box>
                            <img alt={adsList?.song_name}
                                src={process.env.REACT_APP_BASEURL_MEDIA + '/media/' + adsList?.song_poster}
                                style={{ height: 160, width: 160, objectFit: 'contain' }} />
                        </Box>
                        <Box>
                            <Typography gutterBottom variant="h5" component="div">
                                {adsList?.song_name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {adsList?.description}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'end' }}>
                        <a href={adsList?.platform_url} target='_blank' >
                            <Button variant="contained"
                                className='gn-actionbtn fs-6'>Visit Now</Button>
                        </a>
                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    );
}
