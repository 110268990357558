import React from 'react';
function Support(props) {
  return (
    <div>
      {/* <div className='container-fluid p-0'> */}
      <div className={'p-4'}>
        <div className="row">
          <h2>Welcome to GNTunes Support Helpdesk</h2>
          <div className='d-flex justify-content-between'>
            <span className='text-secondary'>In case you have gone through the FAQs and pricing page and still have a query, connect with our representative. </span>
          </div>
          <div className="py-2">
            <div className='card shadow rounded'>
              <div className="card-body ">
                <div className="">
                  <div className="row m-0">
                    <div className="col-md-12">
                      <p className='text-secondary'>
                        At <strong>GNTunes</strong>, we are committed to providing exceptional
                        support to all our customers. Our support desk is here to assist you with any inquiries,
                        technical issues, or feedback you may have regarding our products and services
                      </p>
                      {/* <label className="small my-2 fw-bold">
              GNTunes
            </label> */}
                      <label className="small my-2 fw-bold">Email: </label>
                      <span className="small my-2 text-danger"> <a href='mailto:support@gntunes.com'> support@gntunes.com</a></span>
                      <br />
                      <label className="small my-2 fw-bold">Phone: </label>
                      <span className="small my-2 text-secondary "> Pooja : +91-8708284838, Karthik: +91-9873417752</span>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;