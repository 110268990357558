import React, { useRef, useState, useEffect } from 'react';
import { Checkbox, Drawer, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getGenre, getLanguage } from '../../../../_services/profile'
import { createPlaylist } from '../../../../_services/playlist/createPlaylist';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../../redux';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import { updatePlaylist } from '../../../../_services/playlist/updatePlaylist';
import ApiService from '../../../../_services/ApiService';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function PlaylistDrawer({ isOpen, onClose, onCreatePlaylist, playlistData, type }) {
  const smallViewport = useMediaQuery('(max-width:768px)');
  const [selectedFile, setSelectedFile] = useState(playlistData ? playlistData.playlist_cover_image : null);
  const [playlistName, setPlaylistName] = useState(playlistData ? playlistData.playlist_name : '');
  const [playlistNameErr, setPlatlistNameErr] = useState(null)
  const [playlistNameErrMsg, setPlatlistNameErrMsg] = useState('')
  const [selectedPrivacy, setSelectedPrivacy] = useState(playlistData ? playlistData.privacy_status === true ? 'public' : 'private' : 'public');
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [description, setDescription] = useState(playlistData ? playlistData.playlist_description : '')
  const [genres, setGeners] = useState([])
  const [fileError, setFileError] = useState('')
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData.userData);
  const [disableBtn, setBtnDisable] = useState(playlistData ? playlistData.playlist_name && playlistData.playlist_name.length > 0 ? false : true : true)
  const [fileSizeError, setFileSizeError] = useState(false);
  const [playlistSlug, setPlaylistSlug] = useState(playlistData ? playlistData.slug : '');
  const allowedExtensions = ['.png', '.jpg', '.jpeg', '.svg', '.gif'];
  const [mood, setMood] = useState([])
  const [language, setLanguage] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedMood, setSelectedMood] = useState([]);

  const handleClose = () => {
    setSelectedFile(null);
    setPlaylistName('');
    setSelectedGenres([])
    setSelectedPrivacy('');
    onClose();
  }

  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const fileValidation = (file) => {
    const fileExtension = '.' + file.name.split('.').pop();
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      setFileError('Please upload a valid file.');
      setSelectedFile('');
      return;
    } if (file.size > MAX_FILE_SIZE) {
      setFileSizeError(true);
      setFileError('File size exceeds 2MB limit.');
      setSelectedFile('');
      return;
    } else {
      setFileSizeError(false);
      setSelectedFile(file);
      setFileError('')
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileValidation(file)
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      fileValidation(file)
    }
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    getGenreList()
    getMoodList()
    getLanguageList()
  }, [])
  const getMoodList = () => {
    ApiService.get('getMood').then(function (response) {
      setMood(response.data.data)
      if (playlistData && playlistData.mood.length > 0) {
        const matchedMood = response.data.data
          .filter((item) => playlistData?.mood.includes(item.mood))
          .map((item) => item.id);
        setSelectedMood(matchedMood)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }
  const getLanguageList = () => {
    getLanguage().then(function (response) {
      setLanguage(response.data.data)
      if (playlistData && playlistData.language.length > 0) {
        const matchedLanguage = response.data.data
          .filter((item) => playlistData?.language.includes(item.language))
          .map((item) => item.id);
        setSelectedLanguage(matchedLanguage)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }
  const getGenreList = () => {
    getGenre().then(function (response) {
      setGeners(response.data.data)
      if (playlistData && playlistData.playlist_genre.length > 0) {
        const matchedGenres = response.data.data
          .filter((item) => playlistData.playlist_genre.includes(item.genre))
          .map((item) => item.id);
        setSelectedGenres(matchedGenres)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }
  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguage(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleMoodChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMood(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleGenreChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'playlist_name') {
      if (e.type == 'blur') {
        let playlistName = value.split(' ').join('-');
        setPlaylistSlug(playlistName);
      } else {
        setPlaylistName(value);
        const charCount = value.length;
        const leadingSpacesRegex = /^\s+|\s+$/;

        if (leadingSpacesRegex.test(value)) {
          setBtnDisable(true);
          setPlatlistNameErr(true);
          setPlatlistNameErrMsg('Start and End spaces are not allowed.');
          return;
        }
        // Handle minimum length error
        if (charCount < 3) {
          setBtnDisable(true);
          setPlatlistNameErr(true);
          setPlatlistNameErrMsg('Minimum three characters are required.');
        } else if (charCount > 60) { // Handle maximum length error
          setBtnDisable(true);
          setPlatlistNameErr(true);
          setPlatlistNameErrMsg('Max limit is 60 characters.');
        } else {
          setBtnDisable(false);
          setPlatlistNameErr(false);
          setPlatlistNameErrMsg('');
        }
      }
    }
    if (name == 'playlist_slug') {
      setPlaylistSlug(value);

    }
  };
  const handleSubmit = () => {
    // Validate playlist details, and create the card if valid
    if (playlistName && selectedGenres && selectedPrivacy) {
      const selectedGenresId = selectedGenres
      const playlistDetails = {
        playlist_name: playlistName,
        slug: playlistSlug ? playlistSlug : null,
        playlist_genre: selectedGenresId.length ? selectedGenresId.join(',') : null,
        privacy_status: selectedPrivacy === 'public',
        user: userData.id,
        playlist_cover_image: type != 'edit' ? selectedFile : playlistData && playlistData.playlist_cover_image !== selectedFile ? selectedFile : null,
        playlist_description: description ? description : null,
        language: selectedLanguage.length ? selectedLanguage.join(',') : null,
        mood: selectedMood.length ? selectedMood.join(',') : null,
      };

      var formData = new FormData()
      for (const key in playlistDetails) {
        if (playlistDetails.hasOwnProperty(key) && playlistDetails[key] !== null) {
          if (key === 'privacy_status') {
            formData.append(key, playlistDetails[key] === true ? true : false);
          }
          else
            formData.append(key, playlistDetails[key]);
        }
      }

      // Call the onCreatePlaylist function to add the new playlist
      //call api to create the playlist
      dispatch(setLoader(true))
      if (type !== 'edit') {
        createPlaylist(formData)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              onCreatePlaylist();
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.playlist.playlistCreate))
              dispatch(setLoader(false))
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
            if (response.status === 400) {
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('error'))
              dispatch(setSnackBarMessage(response?.data?.slug[0]))
              dispatch(setLoader(false))
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
          })
          .catch(function (error) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
          })
      }
      else {
        updatePlaylist(playlistData.playlist_id, formData)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              onCreatePlaylist();
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.playlist.playlistUpdate))
              dispatch(setLoader(false))
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
          })
          .catch(function (error) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
          })
      }

      // Close the drawer
      handleClose();
    } else {
      // Handle validation errors or display a message
    }
  };

  return (
    <Drawer
      sx={{
        width: '400px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: smallViewport ? '300px' : '400px',
          boxSizing: 'border-box',
          backgroundColor: '#ffffff',
        },
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <div>
        <div className="p-2 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'rgb(248 248 248)' }}>
          <div style={{ marginLeft: '16px' }}><h4>{type !== 'edit' ? 'Add Playlist' : 'Edit Playlist'}</h4></div>
          <div style={{ position: 'relative', top: '-5px' }}>
            <button title="Close" className="icononly-btn" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className='p-4'>
          <div
            className="image-uploader "
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none', }}
              ref={fileInputRef}
            />


            <div onClick={() => fileInputRef.current.click()} className="audio-uploader custom-height ">

              <p className='text-primary'><UploadFileIcon style={{ height: '2rem', width: '2rem' }} /></p>
              <div className='d-flex'>
                <div style={{ marginRight: '10px' }}>
                  <span className='text-primary'>Click to upload</span>
                </div>
                <div>
                  <span>or drag and drop</span>
                </div>
              </div>
              <p className='text-secondary' style={{ fontSize: '0.8rem' }}>SVG, PNG, JPG or GIF (max. 2MB)</p>
            </div>
            <div className="text-danger">
              {fileError}
            </div>

            {selectedFile && !fileSizeError && (
              <div className=" mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={type != 'edit' ? URL.createObjectURL(selectedFile) : playlistData && playlistData.playlist_cover_image !== selectedFile ? URL.createObjectURL(selectedFile) : process.env.REACT_APP_BASEURL_MEDIA + '/media/' + selectedFile} alt="Selected" style={{ width: '14rem', height: '14rem' }} />
                {/* <img src={playlistData && playlistData.playlist_cover_image && playlistData.playlist_cover_image !== selectedFile?URL.createObjectURL(selectedFile):process.env.REACT_APP_BASEURL_MEDIA+'/media/'+selectedFile} alt="Selected" style={{ width: '14rem', height: '14rem' }} /> */}
              </div>
            )}
          </div>
          <div className='mb-3 mt-3'>
            <TextField
              size='small'
              name="playlist_name"
              label="Playlist name*"
              variant="outlined"
              className="w-100 rounded mt-2"
              value={playlistName}
              onChange={inputChangeHandler}
              onBlur={inputChangeHandler}
              error={playlistNameErr}
              helperText={playlistNameErr ? playlistNameErrMsg : null}
            />
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Genre</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedGenres}
                onChange={handleGenreChange}
                input={<OutlinedInput label="Select Genre" />}
                renderValue={(selected) => selected.map((value) => genres.find(genre => genre.id === value).genre).join(',')}
                MenuProps={MenuProps}
              >
                {genres && genres.length > 0 && genres.map((genre) => (
                  <MenuItem key={genre.id} value={genre.id}>
                    <Checkbox checked={selectedGenres.indexOf(genre.id) > -1} />
                    <ListItemText primary={genre.genre} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Language</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedLanguage}
                onChange={handleLanguageChange}
                input={<OutlinedInput label="Select Language" />}
                renderValue={(selected) => selected.map((value) => language.find(language => language.id === value).language).join(',')}
                MenuProps={MenuProps}
              >
                {language && language.length > 0 && language.map((language) => (
                  <MenuItem key={language.id} value={language.id}>
                    <Checkbox checked={selectedLanguage.indexOf(language.id) > -1} />
                    <ListItemText primary={language.language} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Mood</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedMood}
                onChange={handleMoodChange}
                input={<OutlinedInput label="Select Mood" />}
                renderValue={(selected) => selected.map((value) => mood.find(mood => mood.id === value).mood).join(',')}
                MenuProps={MenuProps}
              >
                {mood && mood.length > 0 && mood.map((mood) => (
                  <MenuItem key={mood.id} value={mood.id}>
                    <Checkbox checked={selectedMood.indexOf(mood.id) > -1} />
                    <ListItemText primary={mood.mood} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='mb-3'>
            <FormControl sx={{ marginTop: '10px' }} fullWidth size='small' >
              <InputLabel >Playlist privacy*</InputLabel>
              <Select
                labelId="Playlist privacy"
                input={<OutlinedInput label="Playlist privacy *" />}
                value={selectedPrivacy}
                onChange={(e) => setSelectedPrivacy(e.target.value)}
                MenuProps={{
                  style: {
                    maxHeight: 250,
                  },
                }}
              >
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
                {/* Add more genres as needed */}
              </Select>
            </FormControl>
          </div>
          {type !== 'edit' ? null : <div className='mb-3 mt-3'>
            <TextField
              size='small'
              name="playlist_slug"
              label="Slug"
              variant="outlined"
              className="w-100 rounded mt-2"
              value={playlistSlug}
              onChange={inputChangeHandler}
            />
          </div>}
          <div className='mt-4 d-flex justify-content-end '>
            <button className='gn-actionbtn' onClick={handleSubmit} disabled={disableBtn}>Submit</button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default PlaylistDrawer;
