import { Card, CardContent, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import * as React from 'react';
import { genreMoodLanguage } from '../../components/reusableComponents/genreMoodLanguage';
import { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
const minDate = new Date()
// const maxDate = new Date()
// minDate.setDate(minDate.getDate() + 7)
// maxDate.setDate(maxDate.getDate())
export default function ArtistCampaign({ stremingPlatform, handleNextStates, songId, setNextDisable }) {
    const { getMoodList, getLanguageList, getGenreList } = genreMoodLanguage();
    const location = useLocation().state;
    const [genreList, setGenreList] = useState([]);
    const [moodList, setMoodList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState(location?.campaignDetail?.genre || '');
    const [selectedMood, setSelectedMood] = useState(location?.campaignDetail?.mood || '');
    const [selectedLanguage, setSelectedLanguage] = useState(location?.campaignDetail?.language || '');
    const [campaignName, setCampaignName] = useState(location?.campaignDetail?.campaignName || '');
    const [taggetAccount, setTaggetAccount] = useState(location?.campaignDetail?.tagging || '');
    const [suggHashtag, setSuggHashtag] = useState(location?.campaignDetail?.suggestedHastag || '');
    const [specialNote, setSpecialNote] = useState(location?.campaignDetail?.notes || '');
    const [startDate, setStartDate] = useState(location?.campaignDetail?.startDate || '');
    const [endDate, setEndDate] = useState(location?.campaignDetail?.endDate || '');
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedMoods = await getMoodList();
                setMoodList(fetchedMoods);

                const fetchedLanguages = await getLanguageList();
                setLanguageList(fetchedLanguages);

                const fetchedGenres = await getGenreList();
                setGenreList(fetchedGenres);
            } catch (err) {
                // setError("Failed to fetch data");
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, []);

    // ----- next click states send 

    useEffect(() => {
        if (selectedGenres || selectedMood || selectedLanguage || campaignName || taggetAccount || suggHashtag || specialNote || selectedPlatform) {
            const values = { selectedGenres, selectedMood, selectedLanguage, campaignName, taggetAccount, suggHashtag, specialNote, selectedPlatform, startDate, endDate }
            setNextDisable(false)
            handleNextStates(values)
        }
    }, [selectedGenres, selectedMood, selectedLanguage, campaignName, taggetAccount, suggHashtag, specialNote, selectedPlatform, startDate, endDate])
    // ----------------- HandleChange ---------

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'genre':
                setSelectedGenres(value);
                break;
            case 'mood':
                setSelectedMood(value);
                break;
            case 'language':
                setSelectedLanguage(value);
                break;
            case 'campaign':
                setCampaignName(value);
                break;
            case 'tagget':
                setTaggetAccount(value);
                break;
            case 'hashtag':
                setSuggHashtag(value);
                break;
            case 'specialNote':
                setSpecialNote(value);
                break;
            case 'platform':
                setSelectedPlatform(Array.isArray(value) ? value : [value]);
                break;
            default:
                break;
        }
    };

    //  Date Change Handler
    const releaseDateChangeHandler = (newValue, pickerType) => {
        const formattedDate = newValue.format('YYYY-MM-DD');
        switch (pickerType) {
            case "startDate":
                setStartDate(formattedDate)
                break;
            case 'endDate':
                setEndDate(formattedDate)
                break;
            default:
                break;
        }

    }

    return (
        <div>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                size='small'
                                className='w-100'
                                id="songId"
                                label="Song Name"
                                defaultValue={songId?.songTitle}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                size='small'
                                className='w-100'
                                label="Campaign Name"
                                name='campaign'
                                value={campaignName}
                                onChange={(e) => onHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="genre-label">Select Genre</InputLabel>
                                <Select
                                    labelId="genre-label"
                                    id="genre-id"
                                    name='genre'
                                    value={selectedGenres}
                                    onChange={(e) => onHandleChange(e)}
                                    input={<OutlinedInput label="Select Genre" />}
                                >
                                    {genreList.length > 0 && genreList.map((genre) => (
                                        <MenuItem key={genre.id} value={genre.id}>
                                            <ListItemText primary={genre.genre} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="mood-label">Select Mood</InputLabel>
                                <Select
                                    labelId="mood-label"
                                    id="mood-id"
                                    value={selectedMood}
                                    name='mood'
                                    onChange={(e) => onHandleChange(e)}
                                    input={<OutlinedInput label="Select Mood" />}
                                >
                                    {moodList && moodList.length > 0 && moodList.map((mood) => (
                                        <MenuItem key={mood.id} value={mood.id}>
                                            <ListItemText primary={mood.mood} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="language-label">Select Language</InputLabel>
                                <Select
                                    labelId="language-label"
                                    id="language"
                                    value={selectedLanguage}
                                    name='language'
                                    onChange={(e) => onHandleChange(e)}
                                    input={<OutlinedInput label="Select Language" />}
                                >
                                    {languageList && languageList.length > 0 && languageList.map((language) => (
                                        <MenuItem key={language.id} value={language.id}>
                                            <ListItemText primary={language.language} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="platform-label">Select Platform</InputLabel>
                                <Select
                                    labelId="platform-label"
                                    id="platform"
                                    multiple
                                    value={selectedPlatform}
                                    name="platform"
                                    onChange={(e) => onHandleChange(e)}
                                    input={<OutlinedInput label="Select Platform" />}
                                    renderValue={(selected) => selected.map((value) => stremingPlatform.find(platform => platform.platformId === value).platformName).join(',')}
                                >
                                    {stremingPlatform &&
                                        stremingPlatform.map((platform) => (
                                            <MenuItem key={platform.platformId} value={platform.platformId}>
                                                <ListItemText primary={platform.platformName} />
                                            </MenuItem>
                                        ))}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                size='small'
                                className='w-100'
                                label="Artist Acoounts to be Tagged"
                                value={taggetAccount}
                                name='tagget'
                                onChange={(e) => onHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth={true} name="start_date">
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label='Start Date'
                                        inputFormat="YYYY-MM-DD"
                                        value={startDate}
                                        name="start_date"
                                        minDate={minDate}
                                        onChange={(date) => releaseDateChangeHandler(date, 'startDate')}
                                        renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth={true} name="end_Date">
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label='End Date'
                                        inputFormat="YYYY-MM-DD"
                                        value={endDate}
                                        name="end_Date"
                                        minDate={startDate}
                                        onChange={(date) => releaseDateChangeHandler(date, 'endDate')}
                                        renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                        disabled={!startDate}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                multiline
                                size='small'
                                className='w-100'
                                label="Suggested Hastag (4-5 tags)"
                                rows={5}
                                name='hashtag'
                                value={suggHashtag}
                                onChange={(e) => onHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                multiline
                                size='small'
                                className='w-100'
                                label="Special Notes"
                                name='specialNote'
                                rows={5}
                                value={specialNote}
                                onChange={(e) => onHandleChange(e)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div >
    );
}
