import ApiService from "../../_services/ApiService";
import { getGenre, getLanguage } from "../../_services/profile";

export const genreMoodLanguage = () => {
  const getMoodList = async () => {
    try {
      const response = await ApiService.get('getMood');
      return response.data.data;
    } catch (error) {
      console.error("Error fetching mood list:", error);
      return error.response || { error: "Unable to fetch mood list" };
    }
  };

  const getLanguageList = async () => {
    try {
      const response = await getLanguage();
      return response.data.data;
    } catch (error) {
      console.error("Error fetching language list:", error);
      return error.response || { error: "Unable to fetch language list" };
    }
  };

  const getGenreList = async () => {
    try {
      const response = await getGenre();
      return response.data.data;
    } catch (error) {
      console.error("Error fetching genre list:", error);
      return error.response || { error: "Unable to fetch genre list" };
    }
  };

  return { getMoodList, getLanguageList, getGenreList };
};
