import React, { useState } from 'react';
import { Avatar, Button, Slider, TextField, Typography, Box, Rating, Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import profileImge from '../../img/profileImage.png'
import ApiService from '../../_services/ApiService';
import { useSelector } from 'react-redux';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';

const Recommendation = ({ openRecommendDialog, setOpenRecommendDialog, recommendData }) => {
    const { successSnack, errorSnack, infoSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [ratingValue, setRatingValue] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    // ----------- 
    const handleChange = (e) => {
        const { value } = e.target
        setFeedback(value)
        if (e.type == 'blur') {
            if (value.length < 30) {
                setError(true);
                setHelperText(`You have written only ${value.length} characters. Please write at least 30 characters.`);
            } else {
                setError(false);
                setHelperText('');
            }
        }
    };


    function sendRecommend() {
        let postData = new FormData()
        postData.append('rating', ratingValue)
        postData.append('feedback', feedback)
        postData.append('recommended_user', recommendData?.id)
        postData.append('recommended_by', userData?.id)

        infoSnack(`Recommendation sending to ${recommendData?.name}`)
        ApiService.post('recommendations', postData).then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                successSnack(`Recommendation successfully send to ${recommendData?.name}`)
                closeRecommendation()
            }
        }).catch(error => {
            errorSnack(error)
            console.log(error)
        })
    }

    // ------------------ close dialog

    const closeRecommendation = () => {
        setOpenRecommendDialog(false)
        setRatingValue(0)
        setFeedback('')
        setError(false)
        setHelperText('')
    }

    return (
        <Dialog open={openRecommendDialog}>
            <DialogTitle> Write a recommendation
                <IconButton
                    aria-label="close"
                    onClick={() => closeRecommendation()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'text.primary',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ maxWidth: 400, margin: 'auto' }}>
                    {/* Avatar and Name */}
                    <Box display="flex" alignItems="center" marginBottom={2}>
                        <Avatar src={recommendData?.avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + recommendData?.avatar : profileImge} alt="Suman Dobriyal" sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography variant="body1">{recommendData?.name}</Typography>
                            <Typography variant="body2" color="textSecondary">{recommendData?.primary_category || '-'}</Typography>
                        </Box>
                    </Box>

                    {/* Rating Stars */}
                    <Rating
                        name="simple-controlled"
                        value={ratingValue}
                        size="large"
                        onChange={(event, newValue) => {
                            setRatingValue(newValue);
                        }}
                        sx={{
                            color: 'rgba(237, 28, 36, 1)',
                            '& .MuiRating-iconFilled': {
                                fontSize: '2rem',
                            },
                            '& .MuiRating-iconEmpty': {
                                fontSize: '2rem',
                            },
                        }}
                    />

                    {/* Recommendation Text Field */}
                    <TextField
                        label="Write your recommendation here"
                        variant="outlined"
                        multiline
                        rows={4}
                        onChange={handleChange}
                        onBlur={handleChange}
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        error={error}
                        helperText={helperText}
                    />

                    {/* Send Button */}
                    <Button variant="contained"
                        className='gn-actionbtn'
                        onClick={() => sendRecommend()}
                        disabled={ratingValue && feedback && !error ? false : true} >
                        Send
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default Recommendation;
