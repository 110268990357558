import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Grid, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const OTPVerification = () => {
    const location = useLocation();
    console.debug("otpverification: code", location)
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [timer, setTimer] = useState(45);

    // Handle OTP Input
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]*$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value.slice(-1); // Allow only 1 digit
            setOtp(newOtp);

            // Move to the next box
            if (value && index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    // Timer Countdown
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // Resend OTP
    const handleResend = () => {
        setTimer(45);
        setOtp(["", "", "", "", "", ""]); // Clear input
    };


    // const code = getCodeFromUserInput();
    // confirmationResult.confirm(code).then((result) => {
    //     // User signed in successfully.
    //     const user = result.user;
    //     // ...
    // }).catch((error) => {
    //     // User couldn't sign in (bad verification code?)
    //     // ...
    // });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundColor: "#f8f8f8",
            }}
        >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
                OTP Verification
            </Typography>
            <Typography variant="body1" color="textSecondary" mb={2}>
                Enter the 6-digit that we have sent via the phone number{" "}
                <b>+91 8882830984</b>
            </Typography>

            {/* OTP Input */}
            <Grid container spacing={1} justifyContent="center" mb={2}>
                {otp.map((value, index) => (
                    <Grid item key={index} xs={1.2}>
                        <TextField
                            id={`otp-input-${index}`}
                            variant="outlined"
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    textAlign: "center",
                                    fontSize: "1.5rem",
                                    height: "2.5rem",
                                    width: "2.5rem",
                                },
                            }}
                            value={value}
                            onChange={(e) => handleChange(e, index)}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Resend Timer */}
            <Box display="flex" justifyContent="space-between" width="100%">
                <Link href="#" underline="hover" color="error" ml={2}>
                    Edit Number
                </Link>
                {timer > 0 ? (
                    <Typography mr={2} color="textSecondary">
                        Resend in {`0:${timer < 10 ? `0${timer}` : timer}`}
                    </Typography>
                ) : (
                    <Link href="#" underline="hover" color="primary" mr={2} onClick={handleResend}>
                        Resend OTP
                    </Link>
                )}
            </Box>

            {/* Verify Button */}
            <Button
                variant="contained"
                color="error"
                sx={{ mt: 2, px: 5, fontSize: "1rem", textTransform: "none" }}
            >
                Verify OTP
            </Button>
        </Box>
    );
};

export default OTPVerification;
