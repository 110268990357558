import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export function CardSkeleton() {
    return (
        <div className="box-card">
            <Skeleton variant="rectangular" height={200} sx={{ margin: '1rem', borderRadius: '8px' }} />
        </div>
    );
}

export function CardSkeletonCollab() {
    return (
        <div className="bg-white rounded mt-4 w-100 d-flex">
            <Skeleton variant="rectangular" height={300} width={400} sx={{ margin: '1rem', borderRadius: '8px' }} />
            <Box>
                {['', '', '', ''].map((d, i)=>(<Skeleton key={i} variant="rectangular" height={20} width={600} sx={{ margin: '1rem', borderRadius: '8px' }} />))}
            </Box>
        </div>
    );
}
