// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDJCItEGNqP309_vU05iYseX3j3tifrSXA",
  authDomain: "groovenexusapp.firebaseapp.com",
  databaseURL: "https://groovenexusapp.firebaseio.com",
  projectId: "groovenexusapp",
  storageBucket: "groovenexusapp.firebasestorage.app",
  messagingSenderId: "58367319560",
  appId: "1:58367319560:web:4d4755b2a7d726db1d3bbd",
  measurementId: "G-SS6M6L908K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app)
const storage = getStorage(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber, db, messaging,storage }
