import React, { useState, useEffect, useCallback, useRef } from "react";
import { AddCircleOutline } from "@mui/icons-material";
// import { DragIndicator } from "@mui/icons-material";
import { EditText } from "react-edit-text";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { Card } from "./card";
import { getCreaterLink } from "../../../_services/creater/creater.service";
import { updateSequence } from "../../../_services/creater/createrSquence.service";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserDetail } from "../../../redux/user/userDataActions";
import SnackbarContainer from "../../Snackbar";
import { TouchBackend } from "react-dnd-touch-backend";
import { fetchLoginUser } from '../../../redux/loginUser/loginUserActions';
// import ShareIcon from '@mui/icons-material/Share';
import LinkTreeView from "./LinkTreeView";
import AddIcon from '@mui/icons-material/Add';
import '../../../style/linkTreeView.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { deleteCreaterDetail } from "../../../_services/creater/createrDelete.service"
import { formSnackbar } from "../../../_services/_constants/snackbar.constant";
import { setSnackBar, setSnackBarMessage, setSnackBarVariant} from "../../../redux";
import { TablePagination } from "@mui/material";


function Links(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const gncreatorsUrl = "https://gncreators.com/";
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [addLink, setAddLink] = useState([]);
  const [linksBKP, setLinksBKP] = useState();
  //const [typeValue, setTypeValue] = useState(false);
  const [disable, setDisable] = useState(false) //to hanlde tha add new button status
  const handleAddClick = () => {
    const items = addLink;
    setAddLink([{},...items]);
    setDisable(true)
  };

  //Code update - 04-11-2022
  const[linksTreeComp, setLinkTreeComp] = useState(<LinkTreeView />);


  // useEffect(
  //   function () {
  //     if (userData.id) {
  //       // getLinkListData(userData.id, page);
  //       getLinkListData(userData.id);

  //     }
  //   }, [userData.id]);

    // }, [userData.id, rowsPerPage, page]);
  
  // const handleChangeRowsPerPage = (event) => {
  //   const newRowsPerPage = parseInt(event.target.value, 10);
  //   const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
  //   setRowsPerPage(newRowsPerPage);
  //   setPage(newPage);
  //   getLinkListData(userData.id, newPage, newRowsPerPage);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  /*function to get the artist links data */
  // function getLinkListData(userId,page)
  function getLinkListData(userId)

  {
    const offset = page * rowsPerPage ;
    const limit = rowsPerPage;
      // getCreaterLink(userId,limit, offset)
      getCreaterLink(userId)
      .then(function (response) {
        if(response.status ===200){
          if(response.data.results.length>0)
          {
            setLinksBKP(response.data.results)
            // setPage(page);
            let arrayData = response.data.results;
            const data = arrayData.sort(function(a, b) { 
              return parseInt(a.display_order) - parseInt(b.display_order)
            });
            setAddLink(data)
            setDisable(false)
          }
          else{
            setDisable(true)
            setAddLink([{},...addLink]);
          }
        }
        else{
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(()=>{dispatch(setSnackBar(false));}, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(()=>{dispatch(setSnackBar(false));}, 2000)
      }) 
  }

  useEffect(
    function () {
      if (userData.id) {
        getLinkListData(userData.id)
      }
    },
    [userData.id]
  );

  const handleOnDragEnd = (result) =>{
    if (!result.destination) return;
    const items = Array.from(addLink);
    const draggedItemId = items[result.source.index].id
    const droppedLocationItemId = items[result.destination.index].id
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAddLink(items);
    const link_ids = items.map(item => item.id)
    let dataObj = {
      link_ids:link_ids?.join(',')
    }
    updateSequence(userData.id,dataObj)
    .then((response)=>{
      if(response.status === 200)
        {handleComponentRerender()}
      else{
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));   
        setTimeout(()=>{dispatch(setSnackBar(false));}, 2000)
      }
    })
    .catch(error=>{
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error')); 
      setTimeout(()=>{dispatch(setSnackBar(false));}, 2000)  
    })
  }

  const linkDeleteHandler = (id, currentIndex) =>{
    const items = addLink;
    if (items.length > 0) {
        const lastIndex = items.length - 1;
        setAddLink(items.filter((item, index) => item.id !== id));
        setDisable(false)
        if(id!=undefined){
          dispatch(setSnackBar(true))
          dispatch(setSnackBarMessage(formSnackbar.creatorLink.linkDelete))
          dispatch(setSnackBarVariant('info'))
          if(JSON.stringify(items[0]) === '{}'){
            setDisable(true)
          }
          let dataObj = {}
          dataObj.userId = userData.id;
          dataObj.linkId  = id;
          deleteCreaterDetail(dataObj)
          .then(function (response) {
              dispatch(setSnackBar(true));
            if(response.status = 200 || response.status ===204){
                setTimeout(()=>{dispatch(setSnackBar(false));}, 3000)
                dispatch(setSnackBarMessage(formSnackbar.creatorLink.linkDeleteSuccess));
                dispatch(setSnackBarVariant('success'));
            }
            else{
                dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
                dispatch(setSnackBarVariant('error'));
                setTimeout(()=>{dispatch(setSnackBar(false));}, 2000)
            }
        })
        .catch(error=>{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>{dispatch(setSnackBar(false));}, 2000)
        })
      }/* if block ends */
    }

  }

  
  const handleComponentRerender = () =>{
    if (userData.id) {
      getLinkListData(userData.id)
    }
    setLinkTreeComp(null)
    setLinkTreeComp(<LinkTreeView />)
  }
  const renderCard = useCallback((card, index,provided) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.name}
        url={card.url}
        data={card}
       /*  typeValue={typeValue} */
        handleComponentRerender = {handleComponentRerender}
        provided={provided}
        deleteHandler = {linkDeleteHandler}
        linksDataList = {addLink}
      />
    );
  }, [addLink]);

  //to enable sharing option
  const shareUserHandler = (url) =>{
      // Select the text field
      //url.select();
      //url.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(url);
      alert("Copied the Link " + url);
  }
  return (
    <React.Fragment>
      <div className="row pb-2">
        <div className="col-12 pt-3">
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <div>
              <h2>Welcome {userDetail.userHandle?userDetail.name:''}</h2>
              <a
                  className="ps-1"
                  href={
                    userDetail.userHandle
                      ? process.env.REACT_APP_CREATORURL + userDetail.userHandle
                      : ""
                  }
                  target="_blank"
                >
                  {userDetail?userDetail.userHandle
                    ? process.env.REACT_APP_CREATORURL + userDetail.userHandle
                    : "":""}
                </a>
                <button title="Share link" className='gn-btn-icononly px-2'> <span onClick={()=>shareUserHandler(userDetail.userHandle?gncreatorsUrl + userDetail.userHandle:'')}> 
                {/* <ShareIcon /> */}
                </span></button>
            </div>
            {/* <div>
              <button className="gn-actionbtn" onClick={()=>shareUserHandler(userDetail.userHandle?gncreatorsUrl + userDetail.userHandle:'')}>Share <ShareIcon /> </button>
            </div> */}
            </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-12 pt-3">
          <div className="row pb-5">
            <div className="creator-links col-sm-12 col-md-12 col-lg-8 col-xl-5">
              <div>
                  <button title="Add Link" disabled={disable?'disabled':''} className="gn-actionbtn" onClick={handleAddClick}><AddIcon />ADD NEW </button>
              </div>
              <div className="links-container">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided)=>(
                            <div {...provided.droppableProps} ref={provided.innerRef} className="characters col-md-12 col-12 col-sm-12 ">
                                {addLink.length>0 && addLink.map((item, index)=> {return (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided)=>(
                                          /*  <AddSong key = {index} deleteSongHandler = {deleteSongHandler} index = {index} id={item.id} data={item} songTitle={item.songTitle} provided={provided} disableHandler={disableHandler} renderSongList={renderSongList}/> */
                                          renderCard(item, index,provided)
                                    )}
                                </Draggable>
                                )})}  
                                {provided.placeholder}  
                            </div>
                        )}

                  </Droppable>
             
                  </DragDropContext>  
              </div>
            </div>
          </div>       
        </div>
      </div>
      <SnackbarContainer />
    </React.Fragment>
  );
}

export default Links;
