import { Avatar, Box, FormControl, Link, Radio, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory, } from 'react-router-dom';
import AddAudio from '../../img/submitSong/addAudio.svg'
import SnackbarContainer from '../Snackbar';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const MusicDemo = ({
  data,
  errors,
  demoType,
  handleInputChange,
  setData,
  submitToList,
  isExternalLink,
  checkboxChangeHandler,
  audio_clip,
  handleDrop,
  selectedValue,
  handleFileInputChange,
  fileInputRef,
  handleFileInputRemove,

}) => {
  let smScreen = useMediaQuery('(max-width:768px)');
  const mdScreen = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    if (selectedValue === 'False' && !isExternalLink[0] && data.video_link) {
      handleInputChange({
        target: {
          name: 'video_link',
          value: '', // Clear the value when the video_link is disabled
        },
      });
    }
    else if (selectedValue === 'True' && isExternalLink[0] && data.external_link) {
      // if(data.external_link)
      handleInputChange({
        target: {
          name: 'external_link',
          value: '', // Clear the value when the external_link is disabled
        },
      });
    }


  }, [selectedValue, isExternalLink, handleInputChange]);


  const handleCheckboxChange = (id) => {
    const newData = { ...data };
    if (newData.receiver_types === undefined) {
      newData.receiver_types = [id];
    } else if (newData.receiver_types.indexOf(id) === -1) {
      newData.receiver_types.push(id);
    } else {
      newData.receiver_types = newData.receiver_types.filter(value => value !== id);
    }
    setData(newData);
  };
  return (
    <>
      <div>
       {(data.demo_type === "True" || data.demo_type == true) ?<div>
          <p className='cardheading'>Demo Composition</p>
          <div className="form-group  mt-2 mb-3">
            <p>Is Video Available?*</p>
            <Radio
              checked={selectedValue === 'True'}
              onChange={handleInputChange}
              value="True"
              name='is_video_available'
              inputProps={{ 'aria-label': 'Yes' }}
            />
            Yes
            <Radio
              checked={selectedValue === 'False'}
              onChange={handleInputChange}
              value="False"
              name='is_video_available'
              inputProps={{ 'aria-label': 'No' }}
            />
            No
          </div>

          <div style={{ opacity: selectedValue === 'False' ? '0.5' : '' }}>
            <TextField
              fullWidth
              size='small'
              id="outlined-required"
              label="Video Link"
              disabled={selectedValue === "False"}
              value={data.video_link}
              name='video_link'
              error={errors.video_link}
              helperText={errors.video_link_helperTxt}
              onChange={handleInputChange}
            />
          </div>
        </div>:null}
        {/* : null} */}
        <div>
          <section>
            {/* {data.demo_type == 'True' ? */}
             {/* <div>
              <label
                htmlFor="fileInput"
                className="audio-uploader"
                style={{
                  pointerEvents: selectedValue === "True" || audio_clip || isExternalLink[0] ? 'none' : 'auto',
                  opacity: selectedValue === "True" || audio_clip || isExternalLink[0] ? '0.3' : '1',
                }}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className={`d-flex align-items-center ${mdScreen ? 'flex-column justify-content-center' : 'flex-row'}`}  >
                  <img src={AddAudio} alt="Upload audio" className="dropImage" />
                  <div className='text-center'>
                    <span className="dragInputText">
                      <a>Click to upload</a> or drag and drop 
                    </span>
                    <span className='d-block p-2 fw-bolder text-body-secondary'>Audio duration should be less than or equal to 1.5 minutes.</span>
                  </div>
                </div>
                <input id="fileInput" type="file" accept="audio/*" onChange={handleFileInputChange} ref={fileInputRef} style={{ display: 'none' }} />
              </label>
            </div>  */}
            
              <Box className="position-relative"   style={{
                  pointerEvents: selectedValue === "True" || audio_clip || isExternalLink[0] ? 'none' : 'auto',
                  opacity: selectedValue === "True"|| audio_clip || isExternalLink[0] ? '0.3' : '1',
                }}>
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: "0.5rem",
                    border: "2px dashed rgba(0, 0, 0, 0.12)",
                    borderRadius: "12px",
                    backgroundColor: "rgba(201, 201, 201, 0.1)",
                    ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                  }}
                >
                  <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                    <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                      <UploadFileIcon color="primary" />
                    </Avatar>
                  </Box>
                  <Box className="upload-btn-wrapper">
                    <span>
                      <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                        Click to upload
                      </Link>
                      <input
                        key={0}
                        type="file"
                        name="myfile"
                        multiple
                        accept=".mp3, .wav"
                        onChange={handleFileInputChange}
                        ref={fileInputRef}
                        className='fileDragDrop'
                      />

                    </span>
                    <span>or drag and drop *</span>
                  </Box>
                  <Box mb='1rem'>
                    <Typography variant="caption" color="text.secondary" display="block" gutterBottom> MP3 and Wave (max. 20mb)</Typography>
                  </Box>
                  {/* <Box mb='1rem'>
                    {extensionErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                      {extensionErr}
                    </Alert> : ""}
                  </Box> */}
                </Stack>
              </Box>
            {/* : null} */}
            {audio_clip && (
              <div className="d-flex align-items-center mt-3 mx-1">
                <div className="flex-grow-1">
                  {smScreen ? <AudioPlayer type="small" audioSrc={audio_clip} /> : <AudioPlayer type="large" audioSrc={audio_clip} />}
                </div>
                <div>
                  <button title="Delete" className="gn-btn-icononly text-secondary" onClick={handleFileInputRemove}>
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            )}
          </section>
          <FormControlLabel
            label="External Link"
            className='mt-2'
            disabled={selectedValue === "True" || audio_clip}
            control={<Checkbox checked={isExternalLink[0]} onChange={checkboxChangeHandler} />}
          />
          {
            isExternalLink[0] && (<FormControl className='mt-2' fullWidth>
              <TextField
                fullWidth
                value={data.external_link}
                disabled={selectedValue === "True"}
                id="outlined-required"
                label="Link*"
                name='external_link'
                error={errors.external_link}
                helperText={errors.external_link_helperTxt}
                onChange={handleInputChange}
              />
            </FormControl>)
          }
        </div>
        <div className="form-group mt-2 mb-3">
          <p>Submit To*</p>
          <div style={{ display: 'flex' }}>
            {submitToList.map((item) => (
              <Box key={item.id}>
                <FormControlLabel
                  key={item.id}
                  className='text-capitalize'
                  control={
                    <Checkbox
                      checked={data.receiver_types && Array.isArray(data.receiver_types) && data.receiver_types.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  }
                  label={item.recipient_type}
                />

              </Box>
            ))}

          </div>
        </div>
        <div>
          <SnackbarContainer />
          {/* <ConfirmModal confirmHandler={confirmHandler} ref={ref}/> */}
        </div>
      </div>
    </>
  )
}

export default MusicDemo