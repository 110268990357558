import React, { useState,useEffect } from "react";
import { useHistory, useLocation, Redirect,useParams} from 'react-router-dom';
import logoCreators from "../img/logoSmall2.png";
import logoGntunes from "../img/logoGntunes.svg";
import backgroundImage from '../img/bg_image_texture3.png'
import Footer from "./footer";
import { Link } from "react-router-dom";
import { login } from "../_services/auth/auth.service";
import { setSelfToken, setUserId } from "../_helpers/auth/secureToken";
import { setLoader } from "../redux";
import { useDispatch } from "react-redux";
import { isLogin } from "../_helpers/auth/secureToken";
import { activateUserEmail } from "../_services/register/ActivateUserEmail";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton, InputAdornment, InputBase, TextField } from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { formSnackbar } from "../_services/_constants/snackbar.constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius:3,
  boxShadow: 24,
  p: 3
};

function Login(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let {tokenKey,tokenValue} = useParams();

  let { from } = location.state || { from: { pathname: "/" } };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passError, setPassError] = useState("");
  const [emailError, setEmailError] = useState();
  const [open, setOpen] = React.useState(false); //to show and hide the modal
  const handleClose=()=>setOpen(false)
  const [modalText, setModalText] = useState('')
  const LogoApp = process.env.REACT_APP_APPURL == 'https://creator.groovenexus.com' ? logoCreators : logoGntunes
  useEffect(()=>{
    if(tokenKey && tokenValue){
      dispatch(setLoader(true))
      let fullURL = tokenKey+'/'+tokenValue+'/'
      activateUserEmail(fullURL)
      .then(function (response) {
          if(response.status === 200){
            dispatch(setLoader(false))
            setOpen(true)
            console.log(response.data.data[0])
            setModalText(response.data.data[0].message)
            setTimeout(()=>setOpen(false),2000)
            //set login path
              setSelfToken(response.data.data[0].token);
              setUserId(response.data.data[0].users.id)
              history.replace(from.pathname);
          }
          else
          {
              dispatch(setLoader(false))
              setOpen(true)
              setModalText('Some error occured while activating your account. Please try again later')
          }
      }).catch(function (error) {
          dispatch(setLoader(true))
          return error.response;
      })
    }
  },[])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  async function loginClickHandler(e) {
    e.preventDefault();
    // let postData = {};
    // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // var regexp = " /^[0-9\b]+$/";
    // var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // if (password.match(regexp) || format.test(password)) {
    //   setPassError("");
    // } else {
    //   setPassError(
    //     <span className="text-danger  small " style={{marginLeft:'10px'}}>{formSnackbar.errors.validPassword}</span>
    //   );
    // }
    // if (username.match(mailformat)) {
    //   setEmailError("");
    // } else {
    //   setEmailError(
    //     <label className="text-danger small " style={{marginLeft:'10px'}}>{formSnackbar.errors.validEmail}</label>
    //   );
    // }
    // if (
    //   (username.match(mailformat) && password.match(regexp)) ||
    //   format.test(password)
    // ) {
    //   dispatch(setLoader(true));
    //   await login({ username, password })
    //     .then(function (response) {
    //       if (response.status === 200) {
    //         dispatch(setLoader(false));
    //         var output = response.data;
    //         if (output) {
              
              // setSelfToken("879492b5d015114902a483ccbfe6a13c73200217ea95990e754a4cb8f5d6bfc5");
    //           setSelfToken(output.token);
    //           history.replace(from.pathname);
    //           dispatch(setLoader(false));
    //         } else {
    //           console.log(response);
    //         }
    //       } else {
    //         console.log("Something Went Wrong, Not able to login");
    //         console.log(
    //           response.data.errors,
    //           "Need to change the code in else condition of self login"
    //         );
    //         dispatch(setLoader(false));
    //         if (response.data.errors) {
    //           dispatch(setLoader(false));
    //           setErrorMsg(
    //             <span className="text-danger mt-1">
    //               {response.data.errors[0].detail}
    //             </span>
    //           );
    //         }
    //         if (response.status !== 200) {
    //           dispatch(setLoader(false));
    //           setErrorMsg(
    //             <span className="text-danger mt-1">
    //               {formSnackbar.errors.invalidCredentials}
    //             </span>
    //           );
    //         }
    //       }
    //     })
    //     .catch(function (error) {
    //       dispatch(setLoader(false));
    //       return error.response;
    //     });
    // }
  }
  if (isLogin()) {
 
    return <Redirect to={from.pathname} />;
  } else {
    return (
      <div className="container-fluid  " style={{height:'100vh',backgroundImage:`url(${backgroundImage})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
        <div className="row">
          <nav className="navbar navbar-light  border pb-0">
            <div className="container-fluid">
              <a className="navbar-brand p-3" href="https://gntunes.com/">
                <img src={LogoApp} alt="logo" />
              </a>
            </div>
          </nav>
          <div className="text-center mt-4">
            <h1>Sign In</h1>
          </div>
          <div style={{maxWidth:'600px',width:'100%',margin:'auto'}}>
            <div className="mt-4 mb-4 p-4 border rounded shadow bg-white">
               <h4 className="text-center cardheading">Welcome To GN Tunes </h4>
                <form action="" method="" className="row g-3">
                <div className="text-center text-secondary mt-4">
                    We are delighted to have you here! 
                  </div>
                  <div >
                    <TextField
                        id="outlined-basic" 
                        label="Email*" 
                        variant="outlined" 
                        size="small"
                        className="w-100"
                        placeholder="Email"
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setEmailError("");
                        }}
                    />
                  </div>
                  {emailError}
                  <div>
                  <TextField
                    label="Password*"
                    size="small"
                    className="w-100"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                  </div>
                  {passError}
                  {errorMsg}
                  <div className="col-md-12 pb-4">
                    <a href="/forgot-password" className="text-decoration-none text-primary">
                      {" "}
                      <strong>Forgot Password?</strong>
                    </a>
                  </div>
                  <div> 
                    <span > Create account? </span>
                      <Link to="/register" className="text-decoration-none text-primary">
                        <strong>Sign up</strong> {" "}
                      </Link>
                    <button
                      type="submit"
                      className="gn-actionbtn float-end py-2"
                      onClick={loginClickHandler}
                    >
                      SIGN IN
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <h6>{modalText}.</h6>
            </Box>
        </Modal>
      </div>
    );
  }
}

export default Login;
