import React, { useState, useContext, useRef } from 'react';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import imgThumbnail from '../../../../img/thumbnail.png';
import { handleRelatedPlatform } from '../../../../_services/creater/relatedPlatfom.service';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import YtMusic from '../../../../img/streaming-platform-images/yt-music.svg';
import JioSaavn from '../../../../img/streaming-platform-images/jio-saavn.svg';
import Wynk from '../../../../img/streaming-platform-images/wynk.svg';
import Spotify from '../../../../img/streaming-platform-images/spotify.svg';
import Hungama from '../../../../img/streaming-platform-images/hungama.svg';
import Gaana from '../../../../img/streaming-platform-images/gaana.svg';
import AmzazonMusic from '../../../../img/streaming-platform-images/amazon-music.svg';
import AppleMusic from '../../../../img/streaming-platform-images/apple-music.svg';
import YouTube from '../../../../img/streaming-platform-images/youtube.png';
import NotEmbeddedYoutube from '../../../../img/streaming-platform-images/embedded.svg';
import EmbeddedYoutube from '../../../../img/streaming-platform-images/embeddedtrue.svg';
import InstagramReel from '../../../../img/streaming-platform-images/instagram_1.svg';
import Deezer from '../../../../img/streaming-platform-images/Deezer.svg';
import Others from '../../../../img/streaming-platform-images/otherPlatform.png';

import {
  setSnackBar,
  setSnackBarMessage,
  setSnackBarVariant,
} from '../../../../redux';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import { StreamingPlatformContext } from './AddSong';
import DeleteModal from '../../../../components/reusableComponents/DeleteModal';
import { useEffect } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const list = [
  {
    platformId: 0,
    platformName: 'Please Select..',
    platform_key: 'pleaseSelect',
  },
];

const AddPlatforms = ({
  deletePlatformHandler,
  reRenderPlatformHandler,
  addNewDisabledHandler,
  cardData,
  songId,
  cardId,
  index,
  allRelatedPlatforms,
  provided
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const { allPlatfromList, handleAllPlatformList, allPlatfromListCopy } =
    useContext(StreamingPlatformContext); // to update streaming platform dropdown as per the selection
  const [selectedPlatform, setSelectedPlatform] = useState(list[0]);
  const [streamingLink, setStreamingLink] = useState(
    cardData ? cardData.streamingSonglink : null
  );
  const [platformImage, setPlatFormImage] = useState(imgThumbnail);
  const [urlError, setUrlError] = useState(false);
  const ref = useRef();
  const [playMode, setPlayMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(true);


  useEffect(() => {
    let platformList = allPlatfromListCopy;
    let selectedItem =
      platformList &&
      platformList.length > 0 &&
      platformList.findIndex((item, index) => {
        return item?.platformId === cardData.streamingPlatform;
      });
    let currentItem =
      platformList &&
      platformList.length > 0 &&
      platformList.find((item, index) => {
        return item?.platformId === cardData.streamingPlatform;
      });
    setSelectedPlatform(platformList[selectedItem]); //set the automatic selected value
    imageChangeHandler(platformList[selectedItem]); //set the image as per the selected item
    setPlayMode(cardData && cardData.play_mode)
    setSelectedStatus(cardData && cardData.status)
    //to auto remove the selected streaming platform from the dropdown
    allRelatedPlatforms &&
      allRelatedPlatforms.length > 0 &&
      handleAllPlatformList(allRelatedPlatforms, 'remove');
  }, []);


  function checkUrlString(string) {
    let expression = /https?:\/\/(www\.|(?!www)[A-Za-z0-9-]+\.[A-Za-z0-9]+[^\s]*)/gi;
    let regex = new RegExp(expression);
    try {
      if (string.match(regex)) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  function checkUrlProtocol(string) {
    try {
      const url = new URL(string);
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        if (url.hostname.substring(0, 1) !== '.') return true;
        else return false;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  const imageChangeHandler = (currentObj) => {
    if (currentObj) {
      switch (currentObj.platform_key) {
        case 'jiosaavn': {
          setPlatFormImage(JioSaavn);
          break;
        }
        case 'wynk': {
          setPlatFormImage(Wynk);
          break;
        }
        case 'spotify': {
          setPlatFormImage(Spotify);
          break;
        }
        case 'hungama': {
          setPlatFormImage(Hungama);
          break;
        }
        case 'gaana': {
          setPlatFormImage(Gaana);
          break;
        }
        case 'amazonmusic': {
          setPlatFormImage(AmzazonMusic);
          break;
        }
        case 'applemusic': {
          setPlatFormImage(AppleMusic);
          break;
        }
        case 'youtubemusic': {
          setPlatFormImage(YtMusic);
          break;
        }
        case 'youtube': {
          setPlatFormImage(YouTube);
          break;
        }
        case 'Instagrammakereels': {
          setPlatFormImage(InstagramReel)
          break;
        }
        case 'others': {
          setPlatFormImage(Others);
          break;
        }
        case 'deezer': {
          setPlatFormImage(Deezer);
          break;
        }
        case undefined: {
          setPlatFormImage(imgThumbnail);
        }
        default: {
          break;
        }
      }
    } else setPlatFormImage(imgThumbnail);
  };

  const platformChangeHandler = (e, newValue) => {
    if (newValue) {
      selectedPlatform != undefined &&
        handleAllPlatformList(
          { previousValue: selectedPlatform, latestValue: newValue },
          'update'
        ); //to remove and add the platforms dropdown if already added platform get updated
      imageChangeHandler(newValue);
      userData.id && cardId == undefined
        ? createRelatedPlatformLink({
          streamingPlatform: newValue?.platformId,
          streamingPlatformCode: newValue.platform_key,
        })
        : updateRelatedPlatformLink({
          streamingPlatform: newValue?.platformId,
          streamingPlatformCode: newValue.platform_key,
        });
      setSelectedPlatform(newValue);
    } else {

    }
  };

  function getId(url, platform) {
    var youTuberegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var spotifyregExp = /https:\/\/open\.spotify\.com\/(track|album|playlist)\/([a-zA-Z0-9]+)(\?si=[a-zA-Z0-9]+)?/;

    if (platform == 'spotify') {
      var match = url.match(spotifyregExp);
      if (match && match[2]) {
        return match[2];
      } else {
        return 'error';
      }
    }
    else {
      var match = url.match(youTuberegExp);
      if (match && match[2].length === 11) {
        return match[2];
      } else {
        return 'error';
      }
    }
  }
  const handlePlatformLinkSave = ({ name, value, previousValue }) => {
    setStreamingLink(value);
    const linkStatus = isYouTubeLink(value)
    let streamLink = '';
    if (linkStatus) {
      const newlink = embededURL(playMode, value)
      streamLink = newlink
    } else {
      streamLink = value
    }
    if (checkUrlString(value)) {
      let validProtocol = checkUrlProtocol(value);

      if (validProtocol) {
        setUrlError(null);
        userData.id && cardId == undefined
          ? createRelatedPlatformLink({ streamingSonglink: streamLink })
          : updateRelatedPlatformLink({ streamingSonglink: streamLink });
      } else {
        setUrlError('Please enter a valid URL');
      }
    } else {
      if (value === '') {
        userData.id && cardId == undefined
          ? createRelatedPlatformLink({ streamingSonglink: streamLink })
          : updateRelatedPlatformLink({ streamingSonglink: streamLink });
        setUrlError(null);
      } else {
        setUrlError('Please enter a valid URL');
      }
    }
  };

  function isYouTubeLink(url) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  }

  const createRelatedPlatformLink = (postObj) => {
    dispatch(setSnackBar(true));
    dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.realtedPlatformCreate));
    dispatch(setSnackBarVariant('info'));
    postObj.songId = songId;
    postObj.userId = userData.id;
    handleRelatedPlatform(postObj, 'POST')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.realtedPlatformCreateSuccess));
          dispatch(setSnackBarVariant('success'));
          setTimeout(() => {
            dispatch(setSnackBar(false));
          }, 2000);
          reRenderPlatformHandler();
          addNewDisabledHandler();
        } else {
          if (response.status == 400) {
            dispatch(setSnackBarMessage(response.data.error[0]));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
          } else {
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const updateRelatedPlatformLink = (postObj) => {
    dispatch(setSnackBar(true));
    dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.realtedPlatformUpdate));
    dispatch(setSnackBarVariant('info'));
    postObj.songId = songId;
    postObj.relatedPlatformLinkId = cardId;
    handleRelatedPlatform(postObj, 'UPDATE')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.realtedPlatformUpdateSuccess));
          dispatch(setSnackBarVariant('success'));
          setTimeout(() => {
            dispatch(setSnackBar(false));
          }, 2000);
          reRenderPlatformHandler();
          addNewDisabledHandler();
        } else {
          if (response.status == 400) {
            dispatch(setSnackBarMessage(response.data.error[0]));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
          } else {
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });

  };

  const updateURLWithYouTubeID = (youtubeID, type) => {
    if (type === 'embed') {
      const url = 'https://www.youtube.com/embed/' + youtubeID;
      return url;
    }
    else {
      const url = 'https://www.youtube.com/watch?v=' + youtubeID;
      return url;
    }
  };

  const updateURLWithSpotifyID = (spotifyID, type) => {
    if (type === 'embed') {
      const url = 'https://open.spotify.com/embed/track/' + spotifyID;
      return url;
    }
    else {
      const url = 'https://open.spotify.com/track/' + spotifyID;
      return url;
    }
  };


  const embededURL = (value, link) => {
    const postObj = {}
    const urlId = getId(link, selectedPlatform.platform_key);
    if (selectedPlatform.platform_key == 'spotify') {
      if (value) {
        const embedLink = updateURLWithSpotifyID(urlId, 'embed')
        setStreamingLink(embedLink)
        return postObj.streamingSonglink = embedLink
      } else {
        const watchLink = updateURLWithSpotifyID(urlId, 'track')
        setStreamingLink(watchLink)
        return postObj.streamingSonglink = watchLink
      }
    } else {
      if (value) {
        const embedLink = updateURLWithYouTubeID(urlId, 'embed')
        setStreamingLink(embedLink)
        return postObj.streamingSonglink = embedLink
      }
      else {
        const watchLink = updateURLWithYouTubeID(urlId, 'watch')
        setStreamingLink(watchLink)
        return postObj.streamingSonglink = watchLink
      }
    }
  }

  const playModeChangeHandler = (event, value) => {
    const isEmbeddedSelected =
      selectedPlatform && selectedPlatform.platform_key === 'youtube' || selectedPlatform && selectedPlatform.platform_key === 'spotify';
    const isLinkPresent =
      streamingLink && streamingLink.trim() !== '';
    if (isEmbeddedSelected && isLinkPresent) {
      setPlayMode(!playMode)
      const newlink = embededURL(value, streamingLink)
      const postObj = {
        songId: songId,
        relatedPlatformLinkId: cardId,
        play_mode: value ? true : false,
        streamingSonglink: newlink
      };
      handleRelatedPlatform(postObj, 'UPDATE')
        .then((response) => {
          if (response.status === 200) {
            dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.realtedPlatformUpdateSuccess));
            dispatch(setSnackBarVariant('success'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
            reRenderPlatformHandler();
            addNewDisabledHandler();
          } else {
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      setUrlError('YouTube platform and link are required.')
    }

  };

  // ------------------- AddStreamingPlatform -----------------

  const platformStatusChangeHandler = (e) => {
    setSelectedStatus(e.target.checked);
    userData.id && cardId == undefined
      ? createRelatedPlatformLink({
        status: e.target.checked,
      })
      : updateRelatedPlatformLink({
        status: e.target.checked,
      });
  };

  return (
    <div className="p-0">
      <React.Fragment key={cardId}>
        <div ref={cardId !== undefined ? provided.innerRef : null} {...(cardId !== undefined ? provided.draggableProps : null)} {...(cardId !== undefined ? provided.dragHandleProps : null)} id={`song_${cardId}`}>
          <div className="row" data-song-id={cardId}>
            <div className="col-md-12 my-2 col-sm-12 col-12 rounded-3 shadow-custom bg-white ps-0" >
              <div>
                <div className="link-box">
                  <div className="drag-hanlder">
                    <div className="my-auto text-secondary cursorGrab text-center">
                      <DragIndicatorIcon />
                    </div>
                  </div>
                  <div className='d-flex flex-column-reverse gap-4' style={{ position: 'absolute', right: '0', top: '5px' }}>
                    {selectedPlatform && (selectedPlatform.platform_key === 'youtube' || selectedPlatform.platform_key === 'spotify') && (
                      <label className="switch text-end cursorPointer">
                        {!playMode ? <img onClick={(e) => playModeChangeHandler(e, true)} src={NotEmbeddedYoutube} title='Embedded Enable' alt={selectedPlatform.platform_key === 'spotify' ? 'EmbeddedSpotify' : 'EmbeddedYoutube'} /> :
                          <img onClick={(e) => playModeChangeHandler(e, false)} title='Embedded Disable' src={EmbeddedYoutube} alt='EmbeddedYoutube' />}
                      </label>
                    )}
                    <label className="switch">
                      <input
                        checked={cardData?.status}
                        type="checkbox"
                        id={cardId}
                        role="switch"
                        onChange={platformStatusChangeHandler}
                      />
                      <span className="slider round" style={{ opacity: (selectedStatus === false && streamingLink === '') ? 0.3 : 1 }} title={selectedStatus === true ? "Status True" : "Status False"}></span>
                    </label>
                  </div>

                  <div className="link-details">
                    <div className="link-inputs">
                      <div className="profile-wrapper w-25">
                        <div className="profile-pic-wrapper">
                          <div className="pic-holder">
                            <img src={platformImage} id="pf-img" className="pic rounded-circle  imageOpacity d-block" alt="img" style={{ width: '71px', height: '71px' }} />
                          </div>
                        </div>
                      </div>
                      <div className="link-wrapper w-75">{/* Link wrapper start */}
                        <div className="link-input-fields bg-transparent" style={{ maxWidth: '90%' }}>
                          <div className="w-100 bg-light rounded-2">
                            <Autocomplete
                              options={allPlatfromList}
                              getOptionLabel={(option) => option.platformName ? option.platformName : ''}
                              id="controlled-demo"
                              value={selectedPlatform}
                              onChange={platformChangeHandler}
                              size="small"
                              className={`removeBorder`}
                              sx={{ padding: '5px' }}
                              renderInput={(params) => (
                                <TextField {...params}
                                  sx={{
                                    '&>div': {
                                      paddingRight: '0px !important'
                                    }
                                  }}
                                  placeholder="Add platform" variant="standard" color="secondary" />
                              )}
                            />
                          </div>

                        </div>
                        <div className="link-input-fields" style={{ maxWidth: '90%' }}>
                          {selectedPlatform && (
                            <div className="w-100">
                              <EditText
                                name="streaming-link"
                                className={`w-100`}
                                onSave={handlePlatformLinkSave}
                                style={{ padding: '0px 4px' }}
                                readonly={selectedPlatform ? false : true} // Disable the input if the platform is not selected
                                defaultValue={streamingLink}
                                placeholder="Add link"
                              />
                              {urlError && <div className={`custom-tooltip`}>
                                <span className="tooltip-text">{urlError}</span>
                                <span className="top"></span>
                              </div>}
                            </div>
                          )}
                        </div>
                        <div className="action-btn-container m-0">
                          <div className="inner-container" style={{ display: 'flex' }}>
                            <div className='link-action-btns'>
                            </div>
                            <div>
                              <div>
                                <button title="Delete" className='gn-btn-icononly text-secondary' href="#"> {cardData && cardData.id !== undefined ? <span id={cardData.id} onClick={() => ref.current.handleOpen()}> <DeleteIcon /></span> : <span id={cardData.id} onClick={(e) => deletePlatformHandler(e, songId, cardId, index, cardData)} className='text-secondary'> <DeleteIcon /></span>}</button>
                              </div>
                            </div>
                          </div>
                        </div>{/* action button container:end */}
                      </div> {/* Link wrapper ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for delete modal */}
        <DeleteModal cardId={cardId && cardId} deleteHandler={(e) => deletePlatformHandler(e, songId, cardId, index, cardData)} ref={ref} />
      </React.Fragment>
    </div>
  );
};

export default AddPlatforms;
