import React, { useState, useEffect } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import ArrowCircleLeftIcon from "../../img/Collabrate/LeftArrow.png";
import ArrowCircleRightIcon from "../../img/Collabrate/RightArrow.png";

const fallbackImages = [
  { label: "First slide", imgPath: require("../../img/artist.png") },
  { label: "Second slide", imgPath: require("../../img/artist1.png") },
  { label: "Third slide", imgPath: require("../../img/artist2.png") },
];

export default function SwipImageCarousel({ collabRequest, count }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation().state;

  // Helper to extract image data
  const getImageData = () => {
    const data =
      location?.data?.results ||
      collabRequest?.results ||
      location?.artistData;
    return (
      data?.[count]?.profile_pictures ||
      data?.profile_pictures ||
      data?.[count]?.sender?.profile_pictures ||
      fallbackImages
    );
  };

  const ImagesList = getImageData();
  const maxSteps = ImagesList.length;

  const handleBack = () =>
    setCurrentIndex((prev) => (prev === 0 ? maxSteps - 1 : prev - 1));

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % maxSteps);
  };
  
  useEffect(() => {
    if (maxSteps === 0) return;
  
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % maxSteps);
    }, 5000);
  
    return () => clearInterval(interval);
  }, [maxSteps]);
  

  const renderButtons = () =>
    ImagesList.map((_, index) => (
      <Button
        key={index}
        size="small"
        sx={{
          minWidth: 40,
          margin: "0 2px",
          padding: "2px",
          backgroundColor: index === currentIndex ? "#F14950" : "#ffffff",
        }}
        onClick={() => setCurrentIndex(index)}
        variant="contained"
      />
    ));

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "500px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      {/* Navigation Buttons */}
      <Box
        display="flex"
        justifyContent="center"
        mt={2}
        position="absolute"
        width="100%"
      >
        {renderButtons()}
      </Box>

      {/* Image Display */}
      <Box
        component="img"
        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        src={ImagesList[currentIndex]?.url || ImagesList[currentIndex]?.imgPath}
        alt={ImagesList[currentIndex]?.label || "Carousel Image"}
      />

      {/* Left Arrow */}
      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
        }}
      >
        <img src={ArrowCircleLeftIcon} alt="Previous Slide" />
      </IconButton>

      {/* Right Arrow */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
        }}
      >
        <img src={ArrowCircleRightIcon} alt="Next Slide" />
      </IconButton>
    </Box>
  );
}
